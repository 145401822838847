import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useReducer, useState } from 'react';
import Config from '../config/Config';
import { daysOfTheWeek } from '../Menu/Menu2';
import { getSubTotal } from '../Order/Order.utils';
import {
    getLocalStorageWithExpiry,
    setLocalStorageWithExpiry,
} from '../Utils/handleLocalStorageWithExpiry';
import { useNotes } from './useNotes';
import { useReviewSubmitted } from './useReviewSubmitted';

//import axios from 'axios';
const { default: axios } = require('axios');
require('datejs');

//deprecated time in local timezone : https://stackoverflow.com/questions/12945003/format-date-as-yyyy-mm-ddthhmmss-sssz
export function getDateTime() {
    var dateTime = new Date().toISOString();
    return dateTime;
}

const reviewReducer = (state, action) => {
    switch (action.type) {
        default:
            var newstate = { ...state };
            console.log('NEW REVIEW ACTION', action);
            newstate[action.key] = action.value;
            console.log('NEW REVIEW state', newstate);
            return newstate;
    }
};

export const POPULAR_SECTION_ID = 'popular';
// TODO : forcedetach for single user feature.: Had following code before in the firebase listener hook.
// if (("forceDetach" in result && result.forceDetach) ||
// ("forceDetach" in result['checkins'][checkinId] && result['checkins'][checkinId].forceDetach))

export function useOrders(
    enqueueSnackbar,
    closeSnackbar,
    showOrders,
    openFood
) {
    const orderReducer = (state, action) => {
        var newOrders;
        switch (action.type) {
            case 'DELETE':
                newOrders = { ...state };
                delete newOrders[action.key];
                if (action.payload.notify) {
                    enqueueSnackbar(action.payload.name + ' deleted.');
                }
                setLocalStorageWithExpiry('orders', JSON.stringify(newOrders));
                return newOrders;

            case 'UPDATE':
                newOrders = { ...state };
                newOrders[action.key] = action.payload;
                if (action.payload.notify) {
                    enqueueSnackbar(action.payload.name + ' updated.');
                }
                setLocalStorageWithExpiry('orders', JSON.stringify(newOrders));
                return newOrders;

            case 'APPEND_NO_NOTIFICATION':
                newOrders = { ...state };
                newOrders[action.key] = action.payload;
                setLocalStorageWithExpiry('orders', JSON.stringify(newOrders));
                return newOrders;

            case 'APPEND':
                newOrders = { ...state };
                newOrders[action.key] = action.payload;
                if (action.payload.notify) {
                    enqueueSnackbar(action.payload.name + ' added.');
                }
                setLocalStorageWithExpiry('orders', JSON.stringify(newOrders));
                return newOrders;
            case 'RESET':
                console.log('RESET IS CALLED ON ORDERS');
                setLocalStorageWithExpiry('orders', JSON.stringify({}));
                return {};
            //case 'SOUND_SETTING'
            default:
                throw new Error();
        }
    };
    const toggleShowOrders = showOrders.toggleShowOrders;
    const reviewComment = useNotes('');
    const appComment = useNotes('');
    const reviewSubmitted = useReviewSubmitted(false);

    const [tipPercent, setTipPercent] = useState(
        parseInt(localStorage.getItem('tipPercent')) || 15
    );

    const [name, setName] = useState(
        localStorage.getItem('nameInLocalStorage') || ''
    );
    const [reviews, dispatchReviews] = useReducer(reviewReducer, {});
    const [checkinId, setCheckinId] = useState(
        localStorage.getItem('checkinId') || ''
    );
    //JSON.parse(localStorage.getItem('orders')|| '{}')
    var defaultOrders = {};
    if (getLocalStorageWithExpiry('orders')) {
        try {
            defaultOrders = JSON.parse(getLocalStorageWithExpiry('orders'));
            //console.log("defaultOrders", defaultOrders);
        } catch {
            defaultOrders = {};
        }
    }
    const [orders, dispatchOrder] = useReducer(orderReducer, defaultOrders);

    const [orderType, setOrderType] = useState(
        localStorage.getItem('orderType') || 'To-go'
    );
    const [customerType, setCustomerType] = useState(
        localStorage.getItem('customerType') || 'Online'
    );
    const [orderNum, setOrderNum] = useState(
        localStorage.getItem('orderNum') || ''
    );
    //const [orders, setOrders] = useState({});
    const [restauId, setRestauId] = useState(
        localStorage.getItem('restauId') || 'DEFAULT'
    );
    const [restauTableKey, setRestauTableKey] = useState('None');
    const [restauName, setRestauName] = useState(
        localStorage.getItem('restauName') || ''
    );
    const [restauHours, setRestauHours] = useState({});
    const [restauAddr, setRestauAddr] = useState(
        localStorage.getItem('restauAddr') || ''
    );
    const [areOrdersPaused, setOrdersPaused] = useState(false);
    const [enablePayLater, setEnablePayLater] = useState(false);
    const [enableDelivery, setEnableDelivery] = useState(false);
    const [deliveryConfig, setDeliveryConfig] = useState(null);

    const [tableId, setTableId] = useState(
        localStorage.getItem('tableId') || ''
    );
    const [qsrMode, setQsrMode] = useState(
        localStorage.getItem('customerType') === 'QSR-Dine-In' ? true : false
    );

    const [outsideBizHours, setOutsideBizHours] = useState(false);

    const [stripeAccount, setStripeAccount] = useState(
        localStorage.getItem('stripeAccount') || ''
    );
    const [promotionMessages, setPromotionMessages] = useState({});

    //JSON.parse(localStorage.getItem('menu')|| '{}')

    var defaultMenu = null;
    if (localStorage.getItem('menu')) {
        try {
            defaultMenu = JSON.parse(localStorage.getItem('menu'));
        } catch {
            defaultMenu = null;
        }
    }
    const [menu, setMenu] = useState(defaultMenu);
    const [disableOrdering, setDisableOrdering] = useState(false);

    const [template, setTemplate] = useState(
        localStorage.getItem('template') || ''
    );
    const [salesTax, setSalesTax] = useState(
        parseFloat(localStorage.getItem('salesTax')) || 0
    );

    const [phoneNumber, setPhoneNumber] = useState(
        localStorage.getItem('phoneNumber') || ''
    );
    const [orderUnderName, setOrderUnderName] = useState(
        localStorage.getItem('orderUnderName') || ''
    );
    const [orderInstruction, setOrderInstruction] = useState(
        localStorage.getItem('orderInstruction') || ''
    );
    const [deliveryAddress, setDeliveryAddress] = useState(
        localStorage.getItem('deliveryAddress') || ''
    );
    const [isDeliveryAddressSelected, setDeliveryAddressSelected] =
        useState(false);
    const [deliveryAPT, setDeliveryAPT] = useState(null);
    const [deliveryFees, setDeliveryFees] = useState(0);
    const [locationConfig, setLocationConfig] = useState(null);
    const [totalInCart, setTotalInCart] = useState(0);
    const [deliveryThreshold, setDeliveryThreshold] = useState(0);

    const [orderHistoryId, setOrderHistoryId] = useState(
        localStorage.getItem('orderHistoryId') || ''
    );
    const [tip, setTip] = useState(
        parseFloat(localStorage.getItem('tip')) || 0
    );
    const [stripePromise, setStripePromise] = useState();
    const [bannerUrl, setBannerUrl] = useState(
        localStorage.getItem('bannerUrl')
    );
    const [bannerUrlDesktop, setBannerUrlDesktop] = useState(
        localStorage.getItem('bannerUrlDesktop')
    );
    const [bannerImageParts, setBannerImageParts] = useState(
        localStorage.getItem('bannerImageParts')
    );

    const [minCreditCardAmt, setMinCreditCardAmt] = useState(
        parseInt(localStorage.getItem('minCreditCardAmt')) || 0
    );
    const [minCreditCardFees, setMinCreditCardFees] = useState(
        parseInt(localStorage.getItem('minCreditCardFees')) || 0
    );
    const [minCreditCardFeesPaid, setMinCreditCardFeesPaid] = useState(0);
    const [deliveryFeesPaid, setDeliveryFeesPaid] = useState(0);

    const [chainId, setChainId] = useState(null);
    const [chainDetails, setChainDetails] = useState(null);
    const [mode, setMode] = useState('store');
    const [pendingPayment, setPendingPayment] = useState(false);
    const [prepTimeConfig, setPrepTimeConfig] = useState(null);
    const [pickupTimes, setPickupTimes] = useState([]);
    const [scheduledDate, setScheduledDate] = useState('');
    const [scheduledTime, setScheduledTime] = useState('');
    const [isOrderAheadEnabled, setOrderAheadEnabled] = useState(false);
    const [scheduledForLater, setScheduledForLater] = useState(
        scheduledTime ? true : false
    );
    const [cardNumber, setCardNumber] = useState(null);
    const [expiry, setExpiry] = useState(null);
    const [cvc, setCvc] = useState(null);
    const [zip, setZip] = useState(null);
    const [thirdPartyConfig, setThirdPartyConfig] = useState(null);
    const [payAtStore, setPayAtStore] = useState(false);
    const [payNow, setPayNow] = useState(true);
    const [disableContaclessPay, setDisableContaclessPay] = useState(true);

    const [deliveryTimes, setDeliveryTimes] = useState([]);
    const [deliveryScheduledDate, setDeliveryScheduledDate] = useState('');
    const [deliveryScheduledTime, setDeliveryScheduledTime] = useState('');
    const [isDeliveryAheadEnabled, setDeliveryAheadEnabled] = useState(false);
    const [deliveryScheduledForLater, setDeliveryScheduledForLater] = useState(
        deliveryScheduledTime ? true : false
    );
    const [deliveryTimings, setDeliveryTimings] = useState(null);
    const [receiptLink, setReceiptLink] = useState(null);
    const [acceptScheduleOrdersOnly, setAcceptSchuleOrdersOnly] =
        useState(null);

    useEffect(() => {
        setScheduledForLater(scheduledDate || scheduledTime ? true : false);
    }, [scheduledDate, scheduledTime]);

    const checkinReducer = (state, action) => {
        switch (action.type) {
            case 'ADD_SELF':
                var newstate = { ...state };
                newstate['self'] = action.payload;
                setCheckinId(action.payload);
                localStorage.setItem('checkinId', action.payload);
                console.log('CHECKIN_ID', action.payload);
                return newstate;
            default:
                throw new Error();
        }
    };
    const [checkin, dispatchCheckin] = useReducer(checkinReducer, {
        staleCheckin: false,
    });

    function getRatings(key) {
        if (!(key in reviews)) {
            return { rating: 0 };
        }
        return reviews[key];
    }

    function getDayOfWeek(currDate) {
        let dayOfWeekIndex = currDate.getDay();
        switch (dayOfWeekIndex) {
            case 0:
                return 'Sun';
            case 1:
                return 'Mon';
            case 2:
                return 'Tue';
            case 3:
                return 'Wed';
            case 4:
                return 'Thu';
            case 5:
                return 'Fri';
            case 6:
                return 'Sat';
            default:
                return null;
        }
    }

    function isTimeMatch(timeObjToCheck, currHour, currMin) {
        /* timeObjToCheck : {
            startHour:'14',
            startMin:'30',
            endHour:'18',
            endMin:'45'
        } */
        if (
            currHour >= timeObjToCheck['startHour'] &&
            currHour <= timeObjToCheck['endHour']
        ) {
            if (
                (currHour === timeObjToCheck['startHour'] &&
                    currMin < timeObjToCheck['startMin']) ||
                (currHour === timeObjToCheck['endHour'] &&
                    currMin > timeObjToCheck['endMin'])
            ) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    async function getRestauAndTableId() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        var rid = params.get('restauId'); // None
        var mode = 'store';
        var chainId = null;
        var referrer = {
            utm_source: params.get('utm_source'),
            utm_medium: params.get('utm_medium'),
            utm_campaign: params.get('utm_campaign'),
        };
        if (document.referrer) {
            referrer['referrer_url'] = document.referrer;
        }

        if (params.get('r')) {
            referrer['utm_source'] = params.get('r').trim();
        }

        if (params.get('pn')) {
            var pn = params.get('pn').trim();
            if (pn.startsWith('+1')) {
                pn = pn.replace(/^\+1/, '');
            } else {
                pn = pn.replace(/^1/, '');
            }
            setPhoneNumber(pn);
            localStorage.setItem('phoneNumber', pn);
        }

        if (!params.get('restauId')) {
            // represents the case when its a browser refresh - when we were rewriting the href.
            //       return null;

            // Since we stopped rewriting the window location. restauId will always be present.
            // if not present we get the last part of pathname as restauId.
            var urlpath = window.location.pathname;
            // replace the last / if present.
            var parts = urlpath.replace(/\/$/, '').split('/');
            if (parts.length >= 2 && parts[parts.length - 2] === 'chain') {
                chainId = parts[parts.length - 1];
                mode = 'chain';
                setChainId(chainId);
            } else if (
                parts.length >= 2 &&
                parts[parts.length - 2] === 'catering'
            ) {
                mode = 'catering';
                rid = parts[parts.length - 1];
            } else if (
                parts.length >= 3 &&
                parts[parts.length - 3] === 'catering'
            ) {
                mode = 'catering';
                rid = parts[parts.length - 2];
            } else if (parts.length >= 1) {
                rid = parts[parts.length - 1];
            }
        }
        console.log('restauId', rid);
        // if (rid === '') {
        //     window.location.replace('./welcome.html');
        //     return;
        // }

        // if (window.location.hostname === 'strideq.com') {
        //     //let newUrl = window.location.href.replace('strideq.com', 'order.strideq.com')
        //     let newUrl = window.location.href.replace(
        //         'strideq.com',
        //         'order.strideq.com'
        //     );
        //     window.location.replace(newUrl);
        //     return;
        // }

        setMode(mode);

        let sourceForVisibilityPolicy = 'Online';
        const tid = params.get('tableId') ? params.get('tableId') : ''; // None
        const qsrMode = params.get('qsrMode');
        if (qsrMode && qsrMode === 'true') {
            setQsrMode(true);
            setCustomerType('QSR-Dine-In');
            setOrderType('Dine-in');
            sourceForVisibilityPolicy = 'DineIn';
        }

        if (rid !== restauId) {
            // if rid is defined in the url and its not a striperedirect, that means person is trying to rescan.
            // Which is not same as page refresh.
            console.log('rid', rid);
            console.log('restauId', restauId);
            console.log('Localstorage cache is cleared.');
            localStorage.clear();
            setMenu('');
            setRestauName('');
            setRestauHours({});
            setRestauAddr('');
            setOrdersPaused(false);
            setTemplate('');
            setSalesTax(0);

            dispatchOrder({ type: 'RESET' });
            setOrderHistoryId('');
            setOrderNum('');
            setTip(0);
            setTipPercent(15);

            setStripePromise(null);
            setBannerUrl(null);
            setBannerUrlDesktop(null);
            setBannerImageParts(null);
            setOrderType('To-go');
            setCustomerType('Online');
            setMinCreditCardAmt(0);
            setMinCreditCardFees(0);
            setMinCreditCardFeesPaid(0);
            setDeliveryFeesPaid(0);
            setDeliveryAddress('');
            setScheduledDate('');
            setScheduledTime('');
            setDeliveryScheduledDate('');
            setDeliveryScheduledTime('');

            setOrderInstruction('');

            // setCheckinId not needed as it will get refreshed.
        }

        const payLaterToken = params.get('payLaterToken')
            ? params.get('payLaterToken')
            : null;
        const deliveryToken = params.get('deliveryToken')
            ? params.get('deliveryToken')
            : null;

        setScheduledDate(params.get('scheduledDate'));
        setScheduledTime(params.get('scheduledTime'));
        setDeliveryScheduledDate(params.get('deliveryScheduledDate'));
        setDeliveryScheduledTime(params.get('deliveryScheduledTime'));
        if (params.get('orderType')) {
            setOrderType(params.get('orderType'));
        }
        setOrderInstruction(params.get('orderInstruction'));
        setDeliveryAddress(params.get('deliveryAddress'));

        let status = params.get('stripeRedirect')
            ? params.get('stripeRedirect')
            : 'None';
        if (params.get('thirdParty')) {
            status = params.get('thirdParty');
        }
        if (status === 'success' || status === 'receipt') {
            debugger;
            setDisableOrdering(true);
            toggleShowOrders(); // opens the cart.
            if (status === 'success') {
                enqueueSnackbar(
                    'Payment successful. Your order will be ready soon.',
                    { variant: 'success' }
                );
            }

            // someone ordered on M,T,W . and opens a sms from tuesday. right details need to pop up. hence cant rely on orders local storage.
            // TODO: if need to optimize the flow from stripe checkout to our app. Need to put ID also stored with data or time.
            try {
                var url = Config.get_order_details_url;
                const resp = await axios.post(url, {
                    restauId: rid,
                    orderId: params.get('orderHistoryId'),
                    orderUnderName: params.get('orderUnderName'),
                });
                console.log('get_order_details response', resp);
                if (resp['data']['status']) {
                    var orderDetails = resp['data']['orderDetails'];
                    // if customer orders at same restaurant again, this ensures they move back to default tip
                    localStorage.removeItem('tipPercent');
                    localStorage.removeItem('tip');
                    localStorage.removeItem('orderType');
                    setMinCreditCardFeesPaid(orderDetails.minCreditCardFees);
                    setDeliveryFeesPaid(orderDetails.deliveryFees);
                    setTip(orderDetails.tip);
                    setOrderNum(orderDetails.number);
                    setCustomerType(orderDetails.customerType);
                    if (orderDetails.customerType === 'QSR-Dine-In') {
                        setQsrMode(true);
                        setTableId(orderDetails.tableId);
                    }
                    setScheduledDate(orderDetails.scheduledDate);
                    setScheduledTime(orderDetails.scheduledTime);
                    setOrderType(orderDetails.orderType);
                    setOrderUnderName(orderDetails.orderUnderName);
                    setOrderInstruction(orderDetails.orderInstruction);
                    setReceiptLink(orderDetails.stripe_strideq_receipt_url);

                    if (
                        orderDetails.paymentStatus === 'UNPAID' &&
                        status === 'receipt'
                    ) {
                        // when status == success, you are redirected from stripe, but the stripe callback might not have happened.
                        // hence setPendingPayment only for receipt.
                        setPendingPayment(true);
                    }

                    dispatchOrder({ type: 'RESET' });
                    for (var k in orderDetails.items) {
                        dispatchOrder({
                            type: 'APPEND_NO_NOTIFICATION',
                            key: k,
                            payload: orderDetails.items[k],
                        });
                    }
                    // Need to clear the cache after it comes back from Stripe Sucess page or in the receipt page.
                    // Else customer makes one QSR order and then rescans the QR again, then already added items are
                    // in the cart by default making a mistake reorder.
                    localStorage.removeItem('orders');
                } else {
                    enqueueSnackbar(
                        'Please Refresh. There was error getting order details for ' +
                            params.get('orderUnderName') +
                            '. ' +
                            resp['msg'],
                        { variant: 'error' }
                    );
                }
            } catch (error) {
                //console.log('axios catch', error);
                enqueueSnackbar(
                    'Please Refresh. There was error getting order details for ' +
                        params.get('orderUnderName') +
                        '. ',
                    { variant: 'error' }
                );
            }
        } else if (status === 'cancel') {
            toggleShowOrders(); // opens the cart.
            enqueueSnackbar('Payment cancelled.', { variant: 'error' });
        }
        if (status !== 'None') {
            setOrderHistoryId(params.get('orderHistoryId'));
            localStorage.setItem(
                'orderHistoryId',
                params.get('orderHistoryId')
            );

            var refreshNeeded = true;
            if (
                restauId !== 'DEFAULT' &&
                restauId === rid &&
                restauName !== '' &&
                menu &&
                Object.keys(orders).length > 0
            ) {
                console.log(
                    'Restaurant data & order present. NO Need to make DB call'
                );
                refreshNeeded = false;
            }

            // TODO: confirm if we can uncomment this.
            // TODO: we can use created_timestamp in orderdetails to our dadvantage. s
            // if (!refreshNeeded){
            //   // No need to refresh the state:
            //   return null;
            // }
        } else {
            const source = params.get('sourceId')
                ? params.get('sourceId')
                : 'MNWSbk9Q5YoWizeCcke'; // which is online
            if (source === 'MNkLQ6pQaGJSYlf8mKf') {
                setCustomerType('Walk-In');
                localStorage.setItem('customerType', 'Walk-In');
                referrer['utm_source'] = 'Walk-In';
            } else if (source === 'MVsUNLj6MGY8nGxlCdV') {
                sourceForVisibilityPolicy = 'DineIn';
                setCustomerType('QSR-Dine-In');
                localStorage.setItem('customerType', 'QSR-Dine-In');
                referrer['utm_source'] = 'QSR-Dine-In';
                setOrderType('Dine-in');
                setQsrMode(true);
            } else if (payLaterToken) {
                setCustomerType('Phone');
                localStorage.setItem('customerType', 'Phone');
                referrer['utm_source'] = 'Phone';
            } else {
                setCustomerType('Online');
                localStorage.setItem('customerType', 'Online');
            }
        }

        const neworder = params.get('neworder') ? params.get('neworder') : 'no';
        if (neworder === 'yes') {
            dispatchOrder({ type: 'RESET' });
            setOrderHistoryId('');
            setOrderNum('');
            setTip(0);
            localStorage.removeItem('orderNum');
            localStorage.removeItem('orderHistoryId');
            localStorage.removeItem('tip');
            localStorage.removeItem('orders');
        }

        /**
         * TODO : Validate restauId and TableId valid.
         */
        setRestauId(rid);
        localStorage.setItem('restauId', rid);
        setTableId(tid);
        localStorage.setItem('tableId', tid);

        const restauTableKey = rid + ':' + tid;
        setRestauTableKey(restauTableKey);

        //console.log(window.location.href)
        //window.history.replaceState({}, document.title, window.location.href.split("?")[0])
        //console.log(window.location.href)

        return {
            restauId: rid,
            tableId: tid,
            restauTableKey,
            referrer,
            mode,
            chainId,
            payLaterToken,
            deliveryToken,
            sourceForVisibilityPolicy,
        };
    }

    function getVisibilityOverrideResultNew(
        currDate,
        source,
        visibilityOverrideId,
        visibilityOverrides,
        isCategory,
        categoryVisOverrideId
    ) {
        //debugger;
        //Assumption: Default visibility of items is True
        let defaultVisibility = true;

        if (isCategory) {
            return true;
        }

        //if the visibilityOverride is not specified at foodItem Level, use the one from category level.
        let curVisibilityOverrideId = visibilityOverrideId
            ? visibilityOverrideId
            : categoryVisOverrideId;

        if (!curVisibilityOverrideId || !currDate || !visibilityOverrides) {
            return defaultVisibility;
        }
        if (!visibilityOverrides[curVisibilityOverrideId]) {
            return defaultVisibility;
        }
        // TODO: Dharma
        let visibilityOverride = visibilityOverrides[curVisibilityOverrideId];
        let currHour = currDate.getHours();
        let currMin = currDate.getMinutes();
        let currDay = getDayOfWeek(currDate);
        //  For each Rule in the override
        let matchedHideRule = false;
        let visibilityResult = false;
        visibilityOverride.rules.forEach((rule) => {
            let ruleMatched = false;
            let ruleTime = null;
            // check if the rule's source is matching with the current source
            let tempSource = source;
            if (source === 'Online') {
                tempSource = 'ToGo';
            }
            if (rule.type.includes(tempSource)) {
                // check if the curTime is witin the time defined in the Rule
                if (rule.dateTimeType === '24/7') {
                    ruleMatched = true;
                } else {
                    if (rule.dateTimeType === 'time') {
                        ruleTime = rule['days']['All Days']['time'];
                    } else if (rule.dateTimeType === 'dayTime') {
                        ruleTime =
                            rule['days'][currDay] &&
                            rule['days'][currDay]['time'];
                    }
                    ruleMatched =
                        ruleTime && isTimeMatch(ruleTime, currHour, currMin);
                }

                if (ruleMatched) {
                    //if there is a matched show rule, then return true
                    if (rule.status === 'show') {
                        visibilityResult = true;
                        return true;
                    } else {
                        matchedHideRule = true;
                    }
                }
            }
        });

        //if there is a matched show rule, then already returned true earlier
        // if (visibilityResult) {
        //     return true;
        // }
        //if there is atleast one matched hide rule, then return false
        if (matchedHideRule) {
            return false;
        }
        //if none of the rules match in the override, then return default value as true
        else {
            return defaultVisibility;
        }
    }

    function getVisibilityOverrideResult(
        visibilityOverride,
        currDate,
        source,
        visibilityOverrideId,
        visibilityOverrides,
        isCategory,
        categoryVisOverrideId
    ) {
        if (
            (visibilityOverrideId || categoryVisOverrideId) &&
            visibilityOverrides
        ) {
            return getVisibilityOverrideResultNew(
                currDate,
                source,
                visibilityOverrideId,
                visibilityOverrides,
                isCategory,
                categoryVisOverrideId
            );
        }

        if (!visibilityOverride || !currDate) {
            return true;
        }
        var sourcePolicy = visibilityOverride[source];
        if (!sourcePolicy) {
            sourcePolicy = visibilityOverride['defaultSource'];
        }
        if (!sourcePolicy) {
            return true;
        }
        var defaultVisibility = true;
        if ('defaultVisibility' in sourcePolicy) {
            defaultVisibility = sourcePolicy['defaultVisibility'];
        }
        var policy = sourcePolicy['visibilityPolicy'];
        if (!policy) {
            return defaultVisibility;
        }
        var time;
        var display;
        if (policy['policyName'] === 'Timebased') {
            time = policy['time'];
            display = policy['display'];
        } else if (policy['policyName'] === 'Daybased') {
            var daypolicy = policy['days'][daysOfTheWeek[currDate.getDay()]];
            if (!daypolicy) {
                return defaultVisibility;
            }
            time = daypolicy['time'];
            display = daypolicy['display'];
        }
        if (!time) {
            return defaultVisibility;
        }

        let currHour = currDate.getHours();
        let currMin = currDate.getMinutes();
        if (currHour >= time['startHour'] && currHour <= time['endHour']) {
            if (
                (currHour == time['startHour'] && currMin < time['startMin']) ||
                (currHour == time['endHour'] && currMin > time['endMin'])
            ) {
                return defaultVisibility;
            }
            return display;
        }
        return defaultVisibility;
    }

    function enhanceMenuWithStationAndModifiersAndOverrides(
        menu,
        popularItems,
        storeTimezone,
        source,
        defaultStations,
        modifiers,
        visibilityOverrides
    ) {
        if (!popularItems) {
            // undefined or null
            popularItems = {};
        }
        var popularSection = {
            id: POPULAR_SECTION_ID,
            name: 'Popular Items',
            foods: [],
        };
        console.log('popularItems', popularItems);

        var currDateStoreTZ;
        if (storeTimezone) {
            var currDate = new Date();
            // TODO  Dharma: TO TEST vis override scenarios, assign the date
            //currDate.setHours(currDate.getHours() + 2); // 24*5);
            currDateStoreTZ = new Date(
                currDate.toLocaleString('en-US', { timeZone: storeTimezone })
            );
        }
        for (const [section_idx, section] of Object.entries(menu)) {
            if (
                !getVisibilityOverrideResult(
                    section.visibilityOverride,
                    currDateStoreTZ,
                    source,
                    section.visiblityOverrideId,
                    visibilityOverrides,
                    true,
                    null
                )
            ) {
                delete menu[section_idx];
                continue;
            }
            if (!section.foods) {
                continue;
            }
            for (const [food_idx, food] of Object.entries(section.foods)) {
                if (
                    food.hide ||
                    !getVisibilityOverrideResult(
                        food.visibilityOverride,
                        currDateStoreTZ,
                        source,
                        food.visiblityOverrideId,
                        visibilityOverrides,
                        false,
                        section.visiblityOverrideId
                    )
                ) {
                    delete section.foods[food_idx];
                    continue;
                }

                food['section_idx'] = section_idx;
                food['food_idx'] = food_idx;

                if (food['modifiers']) {
                    let modifierGroup = {};
                    modifierGroup['modifiers'] = [];
                    for (let mod of food['modifiers']) {
                        let modf = { ...modifiers[mod] };
                        modifierGroup['modifiers'].push(modf);
                    }
                    food['modifierGroup'] = modifierGroup;
                }

                if (!('station' in food)) {
                    if ('station' in section) {
                        food['station'] = section['station'];
                    } else if (defaultStations) {
                        food['station'] = defaultStations;
                    }
                }
                if (food['id'] in popularItems) {
                    popularSection['foods'][popularItems[food['id']]] = food;
                }
            }
            if (Object.keys(section.foods).length === 0) {
                delete menu[section_idx];
            }
        }
        if (popularSection['foods'].length > 0) {
            menu.unshift(popularSection);
        }
        return menu;
    }

    async function getChainNameAndLocations(chainId) {
        if (!chainId || chainId === 'DEFAULT') {
            return;
        }
        try {
            var url = Config.get_chain_details_url;
            const resp = await axios.post(url, {
                chainId,
                currDate: getDateTime(),
            }); //"2021-05-26T06:10:54.661Z"}); //getDateTime()});
            console.log('get_chain_details response', resp);
            if (resp['data']['status']) {
                setChainDetails(resp['data']['chainDetails']);
            } else {
                enqueueSnackbar(
                    'There was error getting chain details. Error Msg= ' +
                        resp['msg'],
                    { variant: 'error' }
                );
            }
        } catch (error) {
            //console.log('axios catch', error);
            enqueueSnackbar(
                'Please Refresh. There was error getting chain details for ' +
                    chainId +
                    '. ',
                { variant: 'error' }
            );
        }
        // const database = window.customerApp.database();
        // var chainRef = database.ref("chain/"+chainId);
        // //let listener =
        // chainRef.once('value').then(function(snapshot) {
        //   var result = snapshot.val();
        //   if (!(result)) {
        //     return;
        //   }
        //   setChainDetails(result);
        // });
    }

    function isWorkingDay(restauTimings, date) {
        let day = daysOfTheWeek[date.getDay()];
        let workingDay = true;
        if (!restauTimings || !day in restauTimings) {
            workingDay = false;
        }
        return workingDay;
    }
    function getTime(dt) {
        var d = new Date(dt.getTime());
        d.setHours(d.getHours()); // offset from local time
        var h = d.getHours() % 12 || 12; // show midnight & noon as 12
        return (
            (h < 10 ? '0' : '') +
            h +
            (d.getMinutes() < 10 ? ':0' : ':') +
            d.getMinutes() +
            // optional seconds display
            // ( d.getSeconds() < 10 ? ':0' : ':') + d.getSeconds() +
            (d.getHours() < 12 ? ' AM' : ' PM')
        );
    }

    var getTimeIntervals = function (
        time1,
        time2,
        bufferFromCurrentTimeInMins,
        bufferFromStartOfSessionInMins,
        nextIntervalGapInMins,
        showTimeRange
    ) {
        // bufferFromCurrentTimeInMins = 45 min
        // bufferFromStartOfSessionInMins = 30 min
        // nextIntervalGapInMins = 15 min
        var arr = [];
        const today = new Date();

        let currentTime = new Date(
            new Date().getTime() + bufferFromCurrentTimeInMins * 60000
        );
        time1 = new Date(
            time1.getTime() + bufferFromStartOfSessionInMins * 60000
        );
        while (time1 < time2) {
            if (
                time1.getDate() == today.getDate() &&
                time1.getMonth() == today.getMonth() &&
                time1.getFullYear() == today.getFullYear() &&
                currentTime > time1
            ) {
            } else {
                if (showTimeRange) {
                    let endTimeForRange = new Date(
                        time1.getTime() + nextIntervalGapInMins * 60000
                    );
                    if (endTimeForRange > time2) {
                        endTimeForRange = time2;
                    }
                    arr.push(getTime(time1) + ' - ' + getTime(endTimeForRange));
                } else {
                    arr.push(getTime(time1)); //.toTimeString().substring(0,5));
                }
            }
            time1 = new Date(time1.getTime() + nextIntervalGapInMins * 60000);
        }
        return arr;
    };

    const getDateKey = (d) => {
        // today = 08042022 or 11242022 or 11042022
        let today =
            ('0' + (d.getMonth() + 1)).slice(-2) +
            ('0' + d.getDate()).slice(-2) +
            d.getFullYear(); //d.toLocaleDateString('en-US');
        return today;
    };
    function getPickupDates(
        restauTimings,
        specialHours,
        scheduledOrderConfig,
        scheduledOrderExtraConfig
    ) {
        let noOfDays = 3;
        let bufferFromCurrentTimeInMins = 45;
        let bufferFromStartOfSessionInMins = 30;
        let nextIntervalGapInMins = 15;
        let showTimeRange = false;
        let whiteListDays = null;
        let enableWhiteListDays = null;
        if (scheduledOrderConfig) {
            if (scheduledOrderConfig.scheduledOrderDays != null) {
                noOfDays = parseInt(scheduledOrderConfig.scheduledOrderDays);
            }
        }
        if (scheduledOrderExtraConfig) {
            if (scheduledOrderExtraConfig.bufferFromCurrentTimeInMins != null) {
                bufferFromCurrentTimeInMins = parseInt(
                    scheduledOrderExtraConfig.bufferFromCurrentTimeInMins
                );
            }
            if (
                scheduledOrderExtraConfig.bufferFromStartOfSessionInMins != null
            ) {
                bufferFromStartOfSessionInMins = parseInt(
                    scheduledOrderExtraConfig.bufferFromStartOfSessionInMins
                );
            }
            if (scheduledOrderExtraConfig.nextIntervalGapInMins != null) {
                nextIntervalGapInMins = parseInt(
                    scheduledOrderExtraConfig.nextIntervalGapInMins
                );
            }
            showTimeRange = scheduledOrderExtraConfig.showTimeRange;
            whiteListDays = scheduledOrderExtraConfig.whiteListDays;
            enableWhiteListDays = scheduledOrderExtraConfig.enableWhiteListDays;
        }
        let date = new Date();
        const dayNames = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ];

        let days = [];
        let dates = {};
        for (let count = 0; count <= noOfDays; count++) {
            //count=0 , represents the today day and noOfDays refers to the future number of days
            let day = daysOfTheWeek[date.getDay()];
            let currRestauTimings = null;
            if (restauTimings && day in restauTimings) {
                currRestauTimings = restauTimings[day];
            }
            let dateKey = getDateKey(date);
            if (specialHours) {
                if (specialHours[dateKey]) {
                    if (specialHours[dateKey].holiday) {
                        date = date.addDays(1);
                        continue;
                    }

                    if (specialHours[dateKey]) {
                        currRestauTimings = specialHours[dateKey].hours;
                    }
                }
            }

            if (currRestauTimings) {
                const strDate =
                    date.getMonth() +
                    1 +
                    '/' +
                    date.getDate() +
                    '/' +
                    date.getFullYear();

                if (enableWhiteListDays && whiteListDays) {
                    //  "8/12/2022" --> "8-12-2022" as firebase cant have "/" in the key
                    // dateKey = 08122022
                    if (!whiteListDays[dateKey]) {
                        date = date.addDays(1);
                        continue;
                    }
                }
                currRestauTimings.forEach((timings) => {
                    let fromDate = new Date(
                        date.toLocaleDateString() +
                            ' ' +
                            Date.parse(timings.start).toLocaleTimeString()
                    );
                    let toDate = new Date(
                        date.toLocaleDateString() +
                            ' ' +
                            Date.parse(timings.end).toLocaleTimeString()
                    );
                    let dayDate = strDate + ' ' + dayNames[date.getDay()];
                    let timeIntervals = getTimeIntervals(
                        fromDate,
                        toDate,
                        bufferFromCurrentTimeInMins,
                        bufferFromStartOfSessionInMins,
                        nextIntervalGapInMins,
                        showTimeRange
                    );
                    if (timeIntervals.length > 0) {
                        if (!dates[dayDate]) {
                            dates[dayDate] = timeIntervals;
                        } else {
                            dates[dayDate] =
                                dates[dayDate].concat(timeIntervals);
                        }
                    }
                });
            }

            date = date.addDays(1);
        }
        for (let dy in dates) {
            days.push({ day: dy, times: dates[dy] });
        }

        console.log(dates);
        return days;
    }

    var getDeliveryTimeIntervals = function (time1, time2) {
        var arr = [];
        const today = new Date();

        let currentTime = new Date(new Date().getTime() + 45 * 60000);
        time1 = new Date(time1.getTime());
        while (time1 < time2) {
            if (
                time1.getDate() == today.getDate() &&
                time1.getMonth() == today.getMonth() &&
                time1.getFullYear() == today.getFullYear() &&
                currentTime > time1
            ) {
            } else {
                arr.push(getTime(time1)); //.toTimeString().substring(0,5));
            }
            time1 = new Date(time1.getTime() + 15 * 60000);
        }
        return arr;
    };

    function getDeliveryDates(
        restauTimings,
        specialHours,
        deliveryTimes,
        noOfDays
    ) {
        let deliveryTimings = deliveryTimes;
        let date = new Date();
        const dayNames = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ];
        let count = 0;
        let days = [];
        let dates = {};
        for (let i = 0; i < 30; i++) {
            if (count >= noOfDays) {
                break;
            }
            var day = daysOfTheWeek[date.getDay()];
            let deliveryDay = deliveryTimings[day];
            if (specialHours) {
                let d = date;
                let today =
                    ('0' + (d.getMonth() + 1)).slice(-2) +
                    '/' +
                    ('0' + d.getDate()).slice(-2) +
                    '/' +
                    d.getFullYear(); //d.toLocaleDateString('en-US');
                today = today.split('/').join('');
                if (specialHours[today]) {
                    if (specialHours[today].holiday) {
                        date = date.addDays(1);
                        continue;
                    }

                    if (specialHours[today]) {
                        deliveryDay = specialHours[today].hours;
                    }
                }
            }

            if (
                isWorkingDay(restauTimings, date) &&
                restauTimings[dayNames[date.getDay()]]
            ) {
                const strDate =
                    date.getMonth() +
                    1 +
                    '/' +
                    date.getDate() +
                    '/' +
                    date.getFullYear();

                if (deliveryDay) {
                    deliveryDay.forEach((timings) => {
                        let fromDate = new Date(
                            date.toLocaleDateString() +
                                ' ' +
                                Date.parse(timings.start).toLocaleTimeString()
                        );
                        let toDate = new Date(
                            date.toLocaleDateString() +
                                ' ' +
                                Date.parse(timings.end).toLocaleTimeString()
                        );
                        let dayDate = strDate + ' ' + dayNames[date.getDay()];
                        if (!dates[dayDate]) {
                            dates[dayDate] = getDeliveryTimeIntervals(
                                fromDate,
                                toDate
                            );
                        } else {
                            dates[dayDate] = dates[dayDate].concat(
                                getDeliveryTimeIntervals(fromDate, toDate)
                            );
                        }
                    });
                    count++;
                }
            }

            date = date.addDays(1);
        }
        for (let dy in dates) {
            days.push({ day: dy, times: dates[dy] });
        }

        console.log(dates);
        return days;
    }

    function getSpecialHours(regularHour, specialHours) {
        if (!specialHours) {
            return regularHour;
        }
        let d = new Date();
        let today =
            ('0' + (d.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + d.getDate()).slice(-2) +
            '/' +
            d.getFullYear(); //d.toLocaleDateString('en-US');
        today = today.split('/').join('');
        if (!specialHours[today]) {
            return regularHour;
        }
        let day = daysOfTheWeek[d.getDay()];
        let newHours = { ...regularHour };
        if (specialHours[today]['holiday']) {
            delete newHours[day];
            return newHours;
        } else if (!specialHours[today]['hours']) {
            return regularHour;
        } else {
            newHours[day] = specialHours[today]['hours'];
            return newHours;
        }
    }

    function getRestauNameAndMenu(
        restauId,
        payLaterToken,
        deliveryToken,
        source
    ) {
        if (!restauId || restauId === 'DEFAULT') {
            return;
        }
        // Extra precaution to ensure if source for visibility policy passed is null we consider it as Online
        if (!source) {
            source = 'Online';
        }
        const database = window.customerApp.database();
        var menuRef = database.ref('menus/' + restauId);
        //let listener =
        menuRef.once('value').then(function (snapshot) {
            var result = snapshot.val();
            if (!result) {
                return;
            }
            setRestauName(result.restaurantName || '');
            localStorage.setItem('restauName', result.restaurantName);

            if ('bannerUrl' in result) {
                setBannerUrl(result.bannerUrl);
                localStorage.setItem('bannerUrl', result.bannerUrl);
            }
            if ('bannerUrlDesktop' in result) {
                setBannerUrlDesktop(result.bannerUrlDesktop);
                localStorage.setItem(
                    'bannerUrlDesktop',
                    result.bannerUrlDesktop
                );
            }
            if ('bannerImageParts' in result) {
                setBannerImageParts(result.bannerImageParts);
                localStorage.setItem(
                    'bannerImageParts',
                    result.bannerImageParts
                );
            }
            setTemplate(result.template);
            localStorage.setItem('template', result.template);
            var enhancedMenu = enhanceMenuWithStationAndModifiersAndOverrides(
                result.menu,
                result.popularItems,
                result.storeTimezone,
                source,
                result.defaultStations,
                result.modifiers,
                result.visibilityOverrides
            );
            setMenu(enhancedMenu);
            localStorage.setItem('menu', JSON.stringify(enhancedMenu));
            var restauTimings = '';
            if (result.details) {
                restauTimings = getSpecialHours(
                    result.details.hours,
                    result.details.special_hours
                );
                setRestauHours(restauTimings || {});
                localStorage.setItem('restauHours', restauTimings);
                if (result.details.scheduledOrderConfig) {
                    if (result.details.scheduledOrderConfig.enabled) {
                        setOrderAheadEnabled(true);
                    }

                    // The reason scheduledOrderConfig is split in 2 parts as the merchant app is overriding ,
                    // other fields when they update settings for the 2 fields it controls : enable & num_of_days.
                    setPickupTimes(
                        getPickupDates(
                            restauTimings,
                            result.details.special_hours,
                            result.details.scheduledOrderConfig,
                            result.details.scheduledOrderExtraConfig
                        )
                    );
                }
                var day = daysOfTheWeek[new Date().getDay()];
                var now = new Date();
                // Test Cases
                // day = "Monday"
                // now = Date.today();
                // now = new Date('February 20, 2021 18:24:00')
                let isOutOfBizHour = false;
                if (restauTimings && day in restauTimings) {
                    //No entry indicates closed for the day
                    //Cannot distinguish between closed and no info since
                    //firebased doesn't allow empty arrays but this forces us to have this info
                    //https://stackoverflow.com/questions/48462093/storing-empty-arrays-in-firebase
                    var obh = day in restauTimings;

                    restauTimings[day].forEach((timings) => {
                        obh =
                            obh &&
                            (now < Date.parse(timings.start) ||
                                now > Date.parse(timings.end));
                    });
                    setOutsideBizHours(obh);
                    isOutOfBizHour = obh;
                } else {
                    isOutOfBizHour = true;
                    setOutsideBizHours(true);
                }

                if (isOutOfBizHour) {
                    // Ensures customer dont have to click on pickup later
                    setScheduledForLater(true);
                }

                if (result.details.acceptScheduleOrdersOnly) {
                    setScheduledForLater(true);
                    setAcceptSchuleOrdersOnly(
                        result.details.acceptScheduleOrdersOnly
                    );
                }

                setRestauAddr(result.details.address || '');
                localStorage.setItem('restauAddr', result.details.address);
                setOrdersPaused(result.details.pause_orders);
                if (isOutOfBizHour && result.details.oob_no_order) {
                    setOrdersPaused(true);
                }
                if (result.details.enablePayLater) {
                    if (result.details.payLaterToken === payLaterToken) {
                        setEnablePayLater(result.details.enablePayLater);
                        setTipPercent(0);
                    }
                }
                if (
                    result.details.deliveryConfig &&
                    result.details.deliveryConfig.enabled &&
                    result.details.deliveryConfig.locationConfig
                    //TODO delivery token ...
                    //&&
                    //result.details.deliveryConfig.deliveryToken ===
                    //   deliveryToken
                ) {
                    setDeliveryConfig(result.details.deliveryConfig);
                    setEnableDelivery(result.details.deliveryConfig.enabled);

                    setDeliveryAheadEnabled(
                        result.details.deliveryConfig.deliveryAheadEnabled
                    );
                    if (result.details.deliveryConfig.locationConfig) {
                        setLocationConfig(
                            result.details.deliveryConfig.locationConfig
                        );
                    }

                    if (result.details.deliveryConfig.deliveryThreshold) {
                        setDeliveryThreshold(
                            result.details.deliveryConfig.deliveryThreshold
                        );
                    }
                    if (result.details.deliveryConfig.hours) {
                        setDeliveryTimings(result.details.deliveryConfig.hours);
                        setDeliveryTimes(
                            getDeliveryDates(
                                restauTimings,
                                result.details.special_hours,
                                result.details.deliveryConfig.hours,
                                result.details.deliveryConfig.scheduledOrderDays
                                    ? result.details.deliveryConfig
                                          .scheduledOrderDays
                                    : 3
                            )
                        );
                    }
                }

                if (result.details.prepTimeConfig) {
                    setPrepTimeConfig(result.details.prepTimeConfig);
                }
            }

            if ('salesTax' in result) {
                setSalesTax(result.salesTax);
                localStorage.setItem('salesTax', result.salesTax);
            }
            if (result.promotionMessages) {
                setPromotionMessages(result.promotionMessages);
            }
            var strAcct = '';
            let isConnectedStripeAccount = false;
            let connectedStripeAccount = null;
            if (result.payments) {
                setSalesTax(result.payments.salesTax);
                localStorage.setItem('salesTax', result.payments.salesTax);
                if (!Config.environment) {
                    // environment = ["Dev-", "Stage-", ""] last one is prod
                    strAcct = result.payments.stripeAccount;
                } else {
                    strAcct = result.payments.stripeAccountTest;
                }
                setStripeAccount(strAcct);
                localStorage.setItem('stripeAccount', strAcct);

                if (result.payments.minCreditCardAmt) {
                    setMinCreditCardAmt(result.payments.minCreditCardAmt);
                    localStorage.setItem(
                        'minCreditCardAmt',
                        result.payments.minCreditCardAmt
                    );
                }

                if (result.payments.minCreditCardFees) {
                    setMinCreditCardFees(result.payments.minCreditCardFees);
                    localStorage.setItem(
                        'minCreditCardFees',
                        result.payments.minCreditCardFees
                    );
                }

                if (result.payments.thirdparty) {
                    setThirdPartyConfig(result.payments.thirdparty);
                }
                setDisableContaclessPay(
                    result.payments.disableContaclessPay ? true : false
                );
                setSalesTax(result.payments.salesTax);
                localStorage.setItem('salesTax', result.payments.salesTax);
                if (result.payments.isConnectedStripeAccount) {
                    isConnectedStripeAccount =
                        result.payments.isConnectedStripeAccount;
                }
                if (result.payments.connectedStripeAccount) {
                    connectedStripeAccount =
                        result.payments.connectedStripeAccount;
                }
            }
            var strPromise = null;
            if (isConnectedStripeAccount && connectedStripeAccount) {
                strPromise = loadStripe(Config.stripeConfig);
            } else if (strAcct) {
                strPromise = loadStripe(Config.stripeConfig, {
                    stripeAccount: strAcct,
                });
            } else {
                strPromise = loadStripe(Config.stripeConfig);
            }
            setStripePromise(strPromise);
            //console.log("strPromise", strPromise, strAcct, Config.stripeConfig );
        });
    }

    function isDeliveryDisabled(restauDeliveryTimings) {
        let day = daysOfTheWeek[new Date().getDay()];
        let now = new Date();
        let isOutOfDeliveryBizHour = false;
        if (day in restauDeliveryTimings) {
            let obh = day in restauDeliveryTimings;
            restauDeliveryTimings[day].forEach((timings) => {
                obh =
                    obh &&
                    (now < Date.parse(timings.start) ||
                        now > Date.parse(timings.end));
            });
            isOutOfDeliveryBizHour = obh;
        } else {
            isOutOfDeliveryBizHour = true;
        }
        return isOutOfDeliveryBizHour;
    }
    function selfCheckIn(rid, referrer) {
        const database = window.customerApp.database();
        var checkinHistoryRef = database
            .ref('checkinhistory/' + rid + '/takeout')
            .push();
        checkinHistoryRef.set({
            key: checkinHistoryRef.key,
            userAgent: window.navigator.userAgent,
            time: getDateTime(),
            referrer: referrer,
        });
        dispatchCheckin({ type: 'ADD_SELF', payload: checkinHistoryRef.key });
    }

    useEffect(
        () => {
            async function setupApp() {
                var result = await getRestauAndTableId();
                if (result) {
                    console.log('Reloading Menu', result);
                    if (result['restauId'] !== restauId) {
                        // if customer is coming from stripe success, where restauId is same as rid, we dont want other entry in checkin.
                        selfCheckIn(result['restauId'], result['referrer']);
                        console.log('CHECKIN id is generated.');
                    } else {
                        console.log('CHECKIN id NOT generated.');
                    }
                    if (result['mode'] === 'chain') {
                        getChainNameAndLocations(result['chainId']);
                    } else {
                        getRestauNameAndMenu(
                            result['restauId'],
                            result['payLaterToken'],
                            result['deliveryToken'],
                            result['sourceForVisibilityPolicy']
                        );
                        // last param is source. It can be Online (from takeout project), DineIn (from dinein project)
                        // and will be used to show menu for that source.
                        // In future, if there is a usecase to show different menu for people Scanning on QR at restau, we can add other source.
                    }
                } else {
                    // read connected account id from localstorage and reload the stripe promise.
                    //TODO : confirm if we need to reload the stripe promise.
                    var strPromise = null;
                    if (stripeAccount) {
                        strPromise = loadStripe(Config.stripeConfig, {
                            stripeAccount: stripeAccount,
                        });
                    } else {
                        strPromise = loadStripe(Config.stripeConfig);
                    }

                    setStripePromise(strPromise);
                }
            }
            setupApp();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getOrderSubtotal = () => getSubTotal(orders);
    return {
        checkin,
        restauId,
        tableId,
        qsrMode,
        restauTableKey,
        orders,
        restauName,
        restauHours,
        restauAddr,
        areOrdersPaused,
        outsideBizHours,
        menu,
        disableOrdering,
        template,
        reviews,
        dispatchReviews,
        salesTax,
        getRatings,
        setOrderHistoryId,
        setOrderNum,
        orderNum,
        orderHistoryId,
        enqueueSnackbar,
        checkinId,
        dispatchCheckin,
        tip,
        setTip,
        name,
        setName,
        dispatchOrder,
        stripePromise,
        bannerUrl,
        bannerUrlDesktop,
        bannerImageParts,
        customerType,
        orderType,
        setOrderType,
        setDisableOrdering,
        minCreditCardAmt,
        minCreditCardFees,
        minCreditCardFeesPaid,
        mode,
        chainDetails,
        phoneNumber,
        setPhoneNumber,
        orderUnderName,
        setOrderUnderName,
        orderInstruction,
        setOrderInstruction,
        enablePayLater,
        setMinCreditCardFeesPaid,
        pendingPayment,
        promotionMessages,
        prepTimeConfig,
        setPrepTimeConfig,
        pickupTimes,
        scheduledTime,
        setScheduledTime,
        scheduledDate,
        setScheduledDate,
        isOrderAheadEnabled,
        enableDelivery,
        deliveryAddress,
        setDeliveryAddress,
        isDeliveryAddressSelected,
        setDeliveryAddressSelected,
        deliveryFees,
        setDeliveryFees,
        deliveryThreshold,
        deliveryFeesPaid,
        deliveryConfig,
        tipPercent,
        setTipPercent,
        reviewComment,
        appComment,
        scheduledForLater,
        setScheduledForLater,
        getOrderSubtotal,
        ...reviewSubmitted,
        ...openFood,
        cardNumber,
        setCardNumber,
        expiry,
        setExpiry,
        cvc,
        setCvc,
        thirdPartyConfig,
        payAtStore,
        setPayAtStore,
        payNow,
        setPayNow,
        zip,
        setZip,
        disableContaclessPay,
        locationConfig,
        setLocationConfig,
        totalInCart,
        setTotalInCart,
        deliveryAPT,
        setDeliveryAPT,
        deliveryScheduledForLater,
        setDeliveryScheduledForLater,
        deliveryTimes,
        setDeliveryTimes,
        deliveryScheduledDate,
        setDeliveryScheduledDate,
        deliveryScheduledTime,
        setDeliveryScheduledTime,
        isDeliveryAheadEnabled,
        setDeliveryAheadEnabled,
        deliveryTimings,
        isDeliveryDisabled,
        receiptLink,
        acceptScheduleOrdersOnly,
        getChainNameAndLocations,
        //openFood,
        showOrders,
    };
}
