import React from 'react';
import styled from 'styled-components';
import { corianderGreen } from '../Styles/colors';
import { Title } from '../Styles/title';
import Config from '../config/Config';

export const NavbarStyled = styled.div`
    background-color: ${corianderGreen};
    padding: 6px 10px;
    position: fixed;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: space-between;
`;

export const Logo = styled(Title)`
    font-size: 17px;
    color: white;
    font-weight: bold;
`;

//  font-size: 25px;
const CartStatus = styled.div`
    color: white;
    margin-right: 20px;
    letter-spacing: 0px;
    display: inline-block;
    margin-top: 2px;
`;
const LogoImage = styled.img`
    width: 79.37px;
    height: 30px;
    left: 20px;
    top: 10px;
    vertical-align: top;
`;

export function Navbar({
    orders,
    toggleShowOrders,
    restauName,
    setShowOrders,
    setOpenFood,
    restauAddr,
    disableOrdering,
}) {
    function closeAllDialogs() {
        setShowOrders(false);
        setOpenFood(null);
    }

    return (
        <NavbarStyled>
            <Logo onClick={closeAllDialogs}>
                {Config.environment}
                {restauName}{' '}
                {restauAddr ? (
                    <a href={restauAddr}>
                        <i
                            style={{ color: '#FFFFFF' }}
                            className="fas fa-map-marker-alt"
                        ></i>
                    </a>
                ) : (
                    <></>
                )}
            </Logo>
            <div>
                {disableOrdering ? (
                    <></>
                ) : (
                    <CartStatus onClick={toggleShowOrders}>
                        <span style={{ color: '#FFFFFF' }}>
                            {' '}
                            <i className="fas fa-cart-plus"></i>{' '}
                        </span>
                        <span
                            role="img"
                            aria-label="Cart"
                            style={{ fontWeight: 'bold' }}
                        >
                            {' '}
                            {Object.keys(orders).length}{' '}
                        </span>
                    </CartStatus>
                )}
            </div>
        </NavbarStyled>
    );
}
