import Config from '../../config/Config';
const { default: axios } = require('axios');

const sendOrder = async (payLater = false, payload, thirdPartyConfig) => {
    let urlKey = payLater
        ? 'create_paylater_order_url'
        : 'create_checkout_session_url';
    if (thirdPartyConfig && thirdPartyConfig.integration_type === 'FULL') {
        urlKey = 'create_checkout_thirdparty';
    } else if (thirdPartyConfig && thirdPartyConfig.pay_at_restaurant) {
        urlKey = 'create_checkout_thirdparty';
    }

    //TODO change it before
    const url = Config[urlKey];
    // API call
    try {
        // let payload = getPayload();
        let resp = await axios.post(url, payload);
        return resp.data;
    } catch (error) {
        console.log(`Error calling ${urlKey}`);
        throw error;
    }
    // success - post-process
};

export default sendOrder;
