import React from 'react';
import PropTypes from 'prop-types';
import FoodSoldOut from './FoodSoldOut';

function FoodTitle({ name, oos }) {
    return (
        <>
            <span opacity="0.5">{name} </span>
            {oos && <FoodSoldOut />}
        </>
    );
}

FoodTitle.propTypes = {};

export default FoodTitle;
