import Typography from '@material-ui/core/Typography';
import React, { useState, useContext, useEffect } from 'react';
import { OrderContext } from '../../context/orderContext';
import Checkbox from './Checkbox';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import styled from 'styled-components';

const SmallInput = styled.input`

    width: 30%;
    margin-right: 2px;
    padding: 10px 5px;
    line-height: 10px,
    border-radius:10px;
    border: .5px solid #b5b5be;
    font-size: 15.5px;
    &:focus {
        outline: none;
        border-color: #27ae60;
        border: 2px solid #27ae60;
  }
`;

const LargeInput = styled.input`

    width: 100%;
    padding: 10px 5px;
    line-height: 10px,
    border-radius: 10px;
    border: .5px solid #b5b5be;
    font-size: 15.5px;
    &:focus {
    outline: none;
    border-color: #27ae60;
    border: 2px solid #27ae60;
  }
`;

function ThirdParty() {
    const {
        cardNumber,
        setCardNumber,
        expiry,
        setExpiry,
        cvc,
        setCvc,
        zip,
        setZip,
        thirdPartyConfig,
        payAtStore,
        setPayAtStore,
        payNow,
        setPayNow,
    } = useContext(OrderContext);
    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getZIPProps,
    } = usePaymentInputs();

    function handleCardNumberChange(e) {
        setCardNumber(e.target.value);
    }
    function handleCardExpiryChange(e) {
        setExpiry(e.target.value);
    }
    function handleCardCVCChange(e) {
        setCvc(e.target.value);
    }
    function handleZipChange(e) {
        setZip(e.target.value);
    }
    return (
        <>
            {thirdPartyConfig &&
                thirdPartyConfig.integration_type === 'HYBRID' && (
                    <>
                        <Typography variant="h6">Payment</Typography>
                        {thirdPartyConfig.pay_at_restaurant && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    marginTop: 5,
                                }}
                            >
                                <Checkbox
                                    label={'Pay now'}
                                    name="payNow"
                                    color="default"
                                    checked={payNow}
                                    onChange={(e) => {
                                        setPayAtStore(!payAtStore);
                                        setPayNow(!payNow);
                                    }}
                                />
                                <Checkbox
                                    label={'Pay at Store'}
                                    name="payAtStore"
                                    color="default"
                                    checked={payAtStore}
                                    onChange={(e) => {
                                        setPayAtStore(!payAtStore);
                                        setPayNow(!payNow);
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            {thirdPartyConfig && thirdPartyConfig.integration_type === 'FULL' && (
                <>
                    <Typography variant="h6">Payment</Typography>
                    {thirdPartyConfig.pay_at_restaurant && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: 5,
                            }}
                        >
                            <Checkbox
                                label={'Pay now'}
                                name="payNow"
                                color="default"
                                checked={payNow}
                                onChange={(e) => {
                                    setPayAtStore(!payAtStore);
                                    setPayNow(!payNow);
                                }}
                            />
                            <Checkbox
                                label={'Pay at Store'}
                                name="payAtStore"
                                color="default"
                                checked={payAtStore}
                                onChange={(e) => {
                                    setPayAtStore(!payAtStore);
                                    setPayNow(!payNow);
                                }}
                            />
                        </div>
                    )}
                    {!payAtStore && (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    marginTop: 5,
                                }}
                            >
                                <LargeInput
                                    size={27}
                                    {...getCardNumberProps({
                                        onChange: handleCardNumberChange,
                                    })}
                                    value={cardNumber}
                                    style={{ borderRadius: '5px' }}
                                    x-autocompletetype="cc-number"
                                    autocomplete="cc-number"
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    marginTop: 5,
                                }}
                            >
                                <SmallInput
                                    size={7}
                                    {...getExpiryDateProps({
                                        onChange: handleCardExpiryChange,
                                    })}
                                    value={expiry}
                                    style={{ borderRadius: '5px' }}
                                    autocomplete="cc-exp"
                                    x-autocompletetype="cc-exp"
                                />
                                <SmallInput
                                    size={6}
                                    {...getCVCProps({
                                        onChange: handleCardCVCChange,
                                    })}
                                    value={cvc}
                                    style={{ borderRadius: '5px' }}
                                    x-autocompletetype="cc-csc"
                                    autocomplete="cc-csc"
                                />
                                <SmallInput
                                    size={7}
                                    {...getZIPProps({
                                        onChange: handleZipChange,
                                    })}
                                    value={zip}
                                    style={{ borderRadius: '5px' }}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

ThirdParty.propTypes = {};

export default ThirdParty;
