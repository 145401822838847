import TextField from '@material-ui/core/TextField';
import React from 'react';

function Nickname({ onChange, value, ...others }) {
    return (
        <div>
            <TextField
                placeholder="Nickname / Initials"
                label="Name for the order"
                name="name"
                maxLength="16"
                size="small"
                fullWidth
                required
                margin="normal"
                variant="outlined"
                value={value}
                {...others}
                onChange={onChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );
}

Nickname.propTypes = {};

export default Nickname;
