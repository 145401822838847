import { useState, useCallback } from 'react';

export function useCrash() {
    const [, setState] = useState();
    return useCallback(
        (err) =>
            setState(() => {
                throw err;
            }),
        []
    );
}
