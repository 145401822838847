import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

//  ${({ img }) => (img ? `background-image: url(${img});` :
//`background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/ramen-banner-edited2.jpeg?alt=media&token=e2bc38ac-4381-419a-96ab-9a095d8da465)`)}

// ${props => props.img !== undefined ?
//   `background-image: url(${img});` :
//   `background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/ramen-banner-edited2.jpeg?alt=media&token=e2bc38ac-4381-419a-96ab-9a095d8da465)`
// }

// background-image: ${props => props.img === undefined ? 'url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/ramen-banner-edited2.jpeg?alt=media&token=e2bc38ac-4381-419a-96ab-9a095d8da465)'
// : `url(${img})`}
// @media(max-width: 720px){
//   background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/banner_optimized.jpg?alt=media&token=7daa55d0-b3e8-4a3d-8659-170b3cbe089b);
// }
// export const BannerDiv = styled.div`
//   height: 200px;
//   @media(max-width: 720px){
//     background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/banner_optimized.jpg?alt=media&token=7daa55d0-b3e8-4a3d-8659-170b3cbe089b);
//   }
//   background-image: url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/food_smallest.jpg?alt=media&token=84cb58b2-ad4f-4a7c-b83e-c9c03fcfba16);

//   background-position: center;
//   background-size: cover;
//   filter: contrast(75%);
// `;

export const BannerDiv = styled.div`
    height: 200px;
    background-image: ${({ imgDesktop }) =>
        imgDesktop
            ? `url(${imgDesktop});`
            : `url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/food_smallest.jpg?alt=media&token=84cb58b2-ad4f-4a7c-b83e-c9c03fcfba16);`};

    @media (max-width: 720px) {
        background-image: ${({ img }) =>
            img
                ? `url(${img});`
                : `url(https://firebasestorage.googleapis.com/v0/b/sliceline-8644d.appspot.com/o/banner_optimized.jpg?alt=media&token=7daa55d0-b3e8-4a3d-8659-170b3cbe089b);`};
    }

    background-position: center;
    background-size: cover;
    filter: contrast(75%);
    position: relative;
    top: 39px;
    background-repeat: no-repeat;
`;

export const AutoBannerDiv = styled.div`
    height: 200px;
    filter: contrast(75%);
    position: relative;
    top: 39px;
`;

export const Logo = styled.span`
    position: absolute;
    top: 50px;
    left: calc(50% - 50px);
    height: 100px;
    width: 100px;
`;

export function Banner({img, imgDesktop, bannerImageParts}) {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return bannerImageParts ? (
        <AutoBannerDiv >
            {windowDimensions.width > 720 ? (
                <>
                    <img
                        height="200px"
                        width="50%"
                        src={bannerImageParts.desktop}
                    ></img>
                    <img
                        height="200px"
                        width="50%"
                        src={bannerImageParts.desktop}
                    ></img>
                </>
            ) : (
                <img
                    height="200px"
                    width="100%"
                    src={bannerImageParts.mobile}
                ></img>
            )}

            <Logo>
                <img
                    width="100px"
                    height="100px"
                    style={{borderRadius: bannerImageParts.logoShape !== 'circle' ? "0%" : "50%"}}
                    src={bannerImageParts.logo}
                ></img>
            </Logo>
        </AutoBannerDiv>
    ) : (
        <BannerDiv img={img} imgDesktop={imgDesktop}></BannerDiv>
    );
}
