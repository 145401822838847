import React, { useContext, useEffect } from 'react';
import { GlobalStyle } from './Styles/GlobalStyle';
import { Chain } from './Chain/Chain';
import { useParams } from 'react-router-dom';
import { OrderContext } from './context/orderContext';
import { useOrders } from './Hooks/useOrders';
import { useSnackbar } from 'notistack';
import { useOpenFood } from './Hooks/useOpenFood';
import { useShowOrders } from './Hooks/useShowOrders';

export default function ChainRoot() {
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // const openFood = useOpenFood();
    // const showOrders = useShowOrders();
    // const { getChainNameAndLocations, chainDetails } = useOrders(
    //     enqueueSnackbar,
    //     closeSnackbar,
    //     showOrders.toggleShowOrders,
    //     openFood
    // );

    const { getChainNameAndLocations, chainDetails } = useContext(OrderContext);

    let { chainId } = useParams();
    useEffect(() => {
        getChainNameAndLocations(chainId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId]);

    return (
        <>
            <GlobalStyle />
            <Chain chainDetails={chainDetails} />
        </>
    );
}
