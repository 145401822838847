import React from 'react';
import CartAction from './components/CartAction';
import OrderTitle from './components/OrderTitle';

function CartEmpty({ qsrMode, tableId, onAddItems }) {
    return (
        <div>
            <OrderTitle qsrMode={qsrMode} tableId={tableId}>
                You can add items to the order and then checkout. We will notify
                you once it is ready.
            </OrderTitle>
            <CartAction label="Add Items" onCartSubmit={onAddItems} />
        </div>
    );
}

CartEmpty.propTypes = {};

export default CartEmpty;
