import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import Comment from './Comment';
import RatingItem from './RatingItem';

function RatingSection() {
    const context = useContext(OrderContext);
    const {
        appComment,
        reviewComment,
        reviewSubmitted,
        getRatings,
        dispatchReviews,
    } = context;

    const setRating = (id, val) => {
        const value = {
            id: id,
            rating: val,
            name: id,
        };
        dispatchReviews({ key: id, value });
    };

    return (
        <div>
            <div>
                {reviewSubmitted ? (
                    <Typography>Thank you for your Feedback.</Typography>
                ) : (
                    <Typography>Please rate and help us improve</Typography>
                )}
            </div>
            <Divider />
            <div>
                <RatingItem
                    id="Online_Ordering"
                    label="Online ordering"
                    defaultValue={getRatings('Online_Ordering').rating}
                    readOnly={reviewSubmitted}
                    onChange={setRating}
                />
                <RatingItem
                    id="Overall_Food"
                    label="Food"
                    defaultValue={getRatings('Overall_Food').rating}
                    readOnly={reviewSubmitted}
                    onChange={setRating}
                />
                <RatingItem
                    id="Overall_Service"
                    label="Service"
                    defaultValue={getRatings('Overall_Service').rating}
                    readOnly={reviewSubmitted}
                    onChange={setRating}
                />
                <Comment
                    label="App Review"
                    name="app_review"
                    value={appComment.value}
                    onChange={appComment.onChange}
                    disabled={reviewSubmitted}
                />
                <Comment
                    label="Food Review"
                    name="food_review"
                    value={reviewComment.value}
                    onChange={reviewComment.onChange}
                    disabled={reviewSubmitted}
                />
            </div>
        </div>
    );
}

RatingSection.propTypes = {};

export default RatingSection;
