/***
 * Errors in event-handlers are not caught out of the box by ErrorBoundary
 * This util helps the error-handlers to surface error to the ErrorBoundary
 * Returns an EventHandler method with CrashHandling logic.
 * @return {function}
 */
import { useCrash } from '../Hooks/useCrash';

export default (eventHandler) => {
    const crash = useCrash();
    return () => {
        try {
            eventHandler();
        } catch (error) {
            const e = new Error('withEventHandlerCrash');
            crash(e);
        }
    };
};
