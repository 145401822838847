import React from 'react';
import PropTypes from 'prop-types';

import { Description } from '../Menu/Menu2';
import { OrderButton, LocationLabel, LocationContainer } from './Chain.styles';
import { loadStorePage } from './Chain.utils';

function Location({ name, address, extra, storeUrl }) {
    const navToRestaurant = () => {
        loadStorePage(storeUrl);
    };

    return (
        <LocationContainer>
            <LocationLabel>
                <div>
                    {name}
                    <Description>{address}</Description>
                    {extra && <Description>{extra}</Description>}
                </div>
                <div>
                    <OrderButton onClick={navToRestaurant}>Order</OrderButton>
                </div>
            </LocationLabel>
        </LocationContainer>
    );
}

Location.defaultProps = {
    extra: '',
};

Location.propTypes = {
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    extra: PropTypes.string,
    storeUrl: PropTypes.string.isRequired,
};

export default Location;
