import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';

function SQCheckbox({ value, label, onChange, ...others }) {
    const handleChange = (event) => {
        onChange(event.target.checked);
    };
    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        color="primary"
                        onChange={handleChange}
                        {...others}
                    />
                }
                label={label}
            />
        </div>
    );
}

SQCheckbox.propTypes = {};

export default SQCheckbox;
