import TextField from '@material-ui/core/TextField';
import React from 'react';

function Comment({ label, name, onChange, value, disabled }) {
    const handleChange = (event) => {
        onChange(event);
    };
    return (
        <div>
            <TextField
                label={label}
                name={name}
                fullWidth
                multiline
                minRows={3}
                margin="normal"
                variant="outlined"
                value={value}
                disabled={disabled}
                size="small"
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );
}

Comment.propTypes = {};

export default Comment;
