import Config from '../config/Config';
import getRestaurantId from './getRestaurantId';
const { default: axios } = require('axios');

const reportErrorUrl = Config.report_error_url;

// one may pass userAgent string to `bowser` package to extract details
// like browser, device, OS
const userAgent = window.navigator.userAgent;

export default async (errorDetails) => {
    let report;
    try {
        report = await axios.post(reportErrorUrl, {
            url: window.location.toString(),
            restauId: getRestaurantId(), // get from url params
            email: '', // leave blank
            logLevelWarning: false,
            app: 'takeout',
            userAgent,
            ...errorDetails,
        });
        // Pass additional parameters to report_error
        // state?
        // data from local-storage
        // inputs?
    } catch (error) {
        console.log(error);
    }

    return report;
};
