function startNewOrder(context, toggleShowOrders) {
    const {
        dispatchOrder,
        setOrderHistoryId,
        setOrderNum,
        setTip,
        setTipPercent,
        setDisableOrdering,
        setOrderUnderName,
        setPhoneNumber,
        restauId,
        tableId,
        qsrMode,
    } = context;

    dispatchOrder({ type: 'RESET' });
    setOrderHistoryId('');
    setOrderNum('');
    setTip(0);
    setTipPercent(15);
    localStorage.removeItem('orderHistoryId');
    localStorage.removeItem('orderNum');
    localStorage.removeItem('tip');
    localStorage.removeItem('tipPercent');
    localStorage.removeItem('orders');
    setDisableOrdering(false);
    toggleShowOrders && toggleShowOrders();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    var payLaterToken = params.get('payLaterToken');
    var deliveryToken = params.get('deliveryToken');

    var urlParts = window.location.href.split('/');
    if (urlParts.length >= 3) {
        var newUrl = urlParts[0] + '//' + urlParts[2] + '/' + restauId;
        if (payLaterToken) {
            setOrderUnderName('');
            setPhoneNumber('');

            localStorage.removeItem('orderUnderName');
            localStorage.removeItem('phoneNumber');

            newUrl += '?payLaterToken=' + payLaterToken;
            window.location.href = newUrl;
            return;
        }
        if (deliveryToken) {
            newUrl += '?deliveryToken=' + deliveryToken;
            window.location.href = newUrl;
            return;
        }
        if (qsrMode) {
            newUrl += '?sourceId=MVsUNLj6MGY8nGxlCdV&tableId=' + tableId;
            window.location.href = newUrl;
            return;
        }
        window.history.pushState({}, null, newUrl);
    }
}

export default startNewOrder;
