import React from 'react';
import PropTypes from 'prop-types';
import { Food, FoodLabel } from '../FoodGrid';
import FoodTitle from './FoodTitle';
import FoodPrice from './FoodPrice';

function FoodItem({ food, setOpenFood }) {
    return (
        <Food
            oos={food.oos}
            img={food.img}
            onClick={() => {
                food.oos
                    ? alert(food.name + ' is temporarily out of stock')
                    : setOpenFood(food);
            }}
        >
            <FoodLabel img={food.img}>
                <div
                    style={{
                        marginRight: '20px',
                    }}
                >
                    <FoodTitle name={food.name} oos={food.oos} />
                </div>
                <div>
                    <FoodPrice {...food} />
                </div>
            </FoodLabel>
        </Food>
    );
}

FoodItem.propTypes = {};

export default FoodItem;
