import React, { useContext } from 'react';
import FormContext from '../FormContext';
import {
    Grid,
    Typography,
    Box,
    Card,
    CardMedia,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    const { formConfig } = useContext(FormContext);
    return (
        formConfig &&
        formConfig.faq &&
        formConfig.faq.length !== 0 && (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h4">
                        Frequently Asked Questions
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    {formConfig.faq.map((fq, i) => (
                        // TODO: have an enabled field, so we can stage the FAQ on admin.
                        <Accordion sx={{ mb: 2 }} key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={i}
                                sx={{ backgroundColor: '#eceff1' }}
                            >
                                <Typography>Q: {fq.question}</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                {fq.answer.content &&
                                    fq.answer.content.map((item, index) => {
                                        if (item.text) {
                                            return (
                                                <Typography key={index}>
                                                    {item.text
                                                        .split('\n')
                                                        .map(
                                                            (
                                                                line,
                                                                lineIndex
                                                            ) => (
                                                                <React.Fragment
                                                                    key={
                                                                        lineIndex
                                                                    }
                                                                >
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                </Typography>
                                            );
                                        } else if (item.img) {
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        overflowX: 'auto',
                                                        flexWrap: 'nowrap',
                                                    }}
                                                >
                                                    {item.img &&
                                                    item.img.length !== 0 ? (
                                                        item.img.map(
                                                            (image, i) => (
                                                                <Box
                                                                    key={i}
                                                                    sx={{
                                                                        margin: 1,
                                                                    }}
                                                                >
                                                                    <Card
                                                                        align="center"
                                                                        sx={{
                                                                            margin: 1,
                                                                            minWidth:
                                                                                '20vh',
                                                                        }}
                                                                    >
                                                                        <CardMedia
                                                                            component="img"
                                                                            height="200"
                                                                            image={
                                                                                image
                                                                            }
                                                                            alt="FAQ image"
                                                                        />
                                                                    </Card>
                                                                </Box>
                                                            )
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Box>
                                            );
                                        }
                                    })}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        )
    );
};

export default FAQ;
