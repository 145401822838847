import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    MenuItem,
    InputAdornment,
    TextField,
    Typography,
    Button,
    Snackbar,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import FormContext from '../FormContext';
import moment from 'moment';

const Form = () => {
    const {
        formConfig,
        setFormConfig,
        form,
        setForm,
        errors,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        updateForm,
        submitForm,
        clearAll,
    } = useContext(FormContext);

    useEffect(() => {}, [formConfig]);

    const renderAdditionalText = (field) => {
        const selectedOption = field.field_type_dropdown.options.find(
            (option) => option.label === form[field.field_name]
        );
        if (!selectedOption || !selectedOption.desc) return null;
        return <Typography>{selectedOption.desc}</Typography>;
    };

    const renderField = (field) => {
        switch (field.field_type) {
            case 'Text':
                return (
                    <TextField
                        required={field.validation.required}
                        label={field.field_label}
                        size="medium"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '90%', margin: 'auto' }}
                        value={form[field.field_name]}
                        helperText={
                            !errors[field.field_name]
                                ? field.field_description
                                    ? field.field_description
                                    : null
                                : errors[field.field_name]
                        }
                        onChange={(e) =>
                            updateForm({
                                ...form,
                                [field.field_name]: e.target.value,
                            })
                        }
                        error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            field.field_type_text.input_adornment
                                ? {
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              {
                                                  field.field_type_text
                                                      .input_adornment
                                              }
                                          </InputAdornment>
                                      ),
                                  }
                                : null
                        }
                    />
                );
            case 'Date':
                return (
                    <DatePicker
                        label={field.field_label}
                        size="small"
                        margin="normal"
                        sx={{ width: '90%', margin: 'auto' }}
                        value={
                            form[field.field_name]
                                ? moment(form[field.field_name], 'YYYY-MM-DD')
                                : null
                        }
                        onChange={(newDate) => {
                            const formattedDate =
                                moment(newDate).format('YYYY-MM-DD');
                            updateForm({
                                ...form,
                                [field.field_name]: formattedDate,
                            });
                        }}
                        //error={!!errors[field.field_name]}
                        slotProps={{
                            textField: {
                                required: field.validation.required,
                                helperText: !errors[field.field_name]
                                    ? field.field_description
                                        ? field.field_description
                                        : null
                                    : errors[field.field_name],
                                error: !!errors[field.field_name],
                            },
                        }}
                        minDate={moment().add(
                            field.field_type_date.min_notice_period,
                            'days'
                        )}
                        maxDate={moment().add(
                            field.field_type_date.max_notice_period
                                ? field.field_type_date.max_notice_period
                                : 365,
                            'days'
                        )}
                    />
                );
            case 'Time':
                var minTime = moment()
                    .set('hour', field.field_type_time.start_hour)
                    .set('minute', field.field_type_time.start_min);
                var maxTime = moment()
                    .set('hour', field.field_type_time.end_hour)
                    .set('minute', field.field_type_time.end_min);
                return (
                    <TimePicker
                        label={field.field_label}
                        size="small"
                        margin="normal"
                        sx={{ width: '90%', margin: 'auto' }}
                        value={
                            form[field.field_name]
                                ? moment(form[field.field_name], 'h:mmA')
                                : null
                        }
                        onChange={(newValue) => {
                            updateForm({
                                ...form,
                                [field.field_name]: newValue.format('h:mmA'),
                            });
                        }}
                        slotProps={{
                            textField: {
                                required: field.validation.required,
                                helperText: !errors[field.field_name]
                                    ? field.field_description
                                        ? field.field_description
                                        : null
                                    : errors[field.field_name],
                                error: !!errors[field.field_name],
                            },
                        }}
                        minTime={minTime}
                        maxTime={maxTime}
                    />
                );
            case 'Number':
                return (
                    <TextField
                        required={field.validation.required}
                        label={field.field_label}
                        size="medium"
                        variant="outlined"
                        margin="normal"
                        sx={{ width: '90%', margin: 'auto' }}
                        value={form[field.field_name]}
                        helperText={
                            !errors[field.field_name]
                                ? field.field_description
                                    ? field.field_description
                                    : null
                                : errors[field.field_name]
                        }
                        onChange={(e) =>
                            updateForm({
                                ...form,
                                [field.field_name]: e.target.value,
                            })
                        }
                        error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={
                            field.field_type_number.input_adornment
                                ? {
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              {
                                                  field.field_type_number
                                                      .input_adornment
                                              }
                                          </InputAdornment>
                                      ),
                                  }
                                : null
                        }
                    />
                );
            case 'Dropdown':
                return (
                    // <Grid item container xs={12} sx={{ margin: 'auto', width: '90%' }}>
                    //     <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        <TextField
                            required={field.validation.required}
                            select
                            label={field.field_label}
                            size="medium"
                            variant="outlined"
                            margin="normal"
                            sx={{ width: '100%', margin: 'auto' }}
                            value={form[field.field_name]}
                            helperText={
                                !errors[field.field_name]
                                    ? field.field_description
                                        ? field.field_description
                                        : null
                                    : errors[field.field_name]
                            }
                            onChange={(e) => {
                                updateForm({
                                    ...form,
                                    [field.field_name]: e.target.value,
                                });
                            }}
                            error={!!errors[field.field_name]}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {field.field_type_dropdown.options.map((option) => (
                                <MenuItem key={option.id} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div>{renderAdditionalText(field)}</div>
                        {/* </Grid>
                         <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {renderAdditionalText(field)}
                         </Grid>
                     </Grid> */}
                    </div>
                );
            case 'Text Area':
                return (
                    <TextField
                        required={field.validation.required}
                        label={field.field_label}
                        size="medium"
                        variant="outlined"
                        margin="normal"
                        multiline
                        minRows={field.field_type_text_area.minRow}
                        sx={{ width: '90%', margin: 'auto' }}
                        value={form[field.field_name]}
                        helperText={
                            !errors[field.field_name]
                                ? field.field_description
                                    ? field.field_description
                                    : null
                                : errors[field.field_name]
                        }
                        onChange={(e) =>
                            updateForm({
                                ...form,
                                [field.field_name]: e.target.value,
                            })
                        }
                        error={!!errors[field.field_name]}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item container spacing={3} sx={{ alignItems: 'flex-start' }}>
                {formConfig &&
                    formConfig.fields &&
                    formConfig.fields.map((field) => (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={4}
                            key={field.field_name}
                            sx={{ display: 'flex' }}
                        >
                            {renderField(field)}
                        </Grid>
                    ))}
            </Grid>
            <Grid item sx={{ margin: 'auto' }}>
                <Button
                    size="medium"
                    variant="contained"
                    style={{ width: 120 }}
                    onClick={submitForm}
                >
                    Submit
                </Button>
                <Button
                    size="medium"
                    variant="contained"
                    sx={{ m: 2 }}
                    onClick={clearAll}
                    color="neutral1"
                >
                    Clear All
                </Button>
            </Grid>
        </Grid>
    );
};

export default Form;

// const renderFurtherOptions = (field) => {
//     const selectedPackageObj = field.validation.dropdown_options.find(
//         (option) => option.value === form[field.field_name]
//     );

//     const selectedOptionObj =
//         selectedPackageObj.additional_option_description.find(
//             (option) => option.value === form[selectedPackageObj.field_name]
//         );

//     if (!selectedOptionObj || !selectedOptionObj.additional_option) {
//         return null;
//     }

//     switch (selectedOptionObj.additional_option) {
//         case 'text':
//             return (
//                 <Typography>
//                     {selectedOptionObj.additional_option_description}
//                 </Typography>
//             );
//         case 'dropdown':
//             return (
//                 <TextField
//                     required
//                     select
//                     label={`${field.field_label} Additional Options`}
//                     size="medium"
//                     variant="outlined"
//                     margin="normal"
//                     sx={{ width: '90%' }}
//                     value={form[selectedOptionObj.field_name]}
//                     onChange={(e) => {
//                         updateForm({
//                             ...form,
//                             [selectedOptionObj.field_name]: e.target.value,
//                         });
//                     }}
//                     InputLabelProps={{
//                         shrink: true,
//                     }}
//                 >
//                     {selectedOptionObj.additional_option_description.map(
//                         (option) => (
//                             <MenuItem value={option.value}>
//                                 {option.value}
//                             </MenuItem>
//                         )
//                     )}
//                 </TextField>
//             );
//         default:
//             return null;
//     }
// };

// const renderAdditionalOptions = (field) => {
//     const selectedPackageObj = field.validation.dropdown_options.find(
//         (option) => option.value === form[field.field_name]
//     );

//     if (!selectedPackageObj || !selectedPackageObj.additional_option) {
//         return null;
//     }

//     switch (selectedPackageObj.additional_option) {
//         case 'text':
//             return (
//                 <Typography>
//                     {selectedPackageObj.additional_option_description}
//                 </Typography>
//             );
//         case 'dropdown':
//             return (
//                 <>
//                     <TextField
//                         required
//                         select
//                         label={`${field.field_label} Additional Options`}
//                         size="medium"
//                         variant="outlined"
//                         margin="normal"
//                         sx={{ width: '90%' }}
//                         value={form[selectedPackageObj.field_name]}
//                         onChange={(e) => {
//                             updateForm({
//                                 ...form,
//                                 [selectedPackageObj.field_name]:
//                                     e.target.value,
//                             });
//                         }}
//                         InputLabelProps={{
//                             shrink: true,
//                         }}
//                     >
//                         {selectedPackageObj.additional_option_description.map(
//                             (option) => (
//                                 <MenuItem value={option.value}>
//                                     {option.value}
//                                 </MenuItem>
//                             )
//                         )}
//                     </TextField>
//                     {renderFurtherOptions(field)}
//                 </>
//             );
//         default:
//             return null;
//     }
// };
