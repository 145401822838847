import Typography from '@material-ui/core/Typography';
import isNumber from 'lodash/isNumber';
import React, { useState, useContext, useEffect } from 'react';
import TipCustom from './TipCustom';
import TipStandard from './TipStandard';
import { OrderContext } from '../../context/orderContext';

import { formatPrice } from '../../Data/FoodData';
import { getTip } from '../Order.utils';

const tipStandard = [10, 15, 20]; // TODO: Read from config later
const tipDefault = 3; // TODO: Read from config
function TipSection() {
    const { tipPercent, setTipPercent, getOrderSubtotal } =
        useContext(OrderContext);

    const indexStandardTip = tipStandard.indexOf(tipPercent);

    const IS_CUSTOM = indexStandardTip === -1;
    const CUSTOM_TIP = IS_CUSTOM ? tipPercent : '';
    const STANDARD_TIP = !IS_CUSTOM
        ? tipPercent
        : tipStandard[indexStandardTip];

    const [standardTip, setStandardTip] = useState(STANDARD_TIP);
    const [isCustom, setIsCustom] = useState(IS_CUSTOM);
    const [customTip, setCustomTip] = useState(CUSTOM_TIP);

    const subTotal = getOrderSubtotal();
    const customTipValue = formatPrice(getTip(subTotal, customTip) / 100);

    useEffect(() => {
        if (isCustom) {
            setTipPercent(customTip);
        } else {
            setTipPercent(standardTip);
        }
    }, [setTipPercent, standardTip, isCustom, customTip]);

    const updateStandardTip = (val) => {
        setStandardTip(val);
        if (isCustom) {
            setIsCustom(false);
        }
    };

    const updateCustomTip = (val) => {
        const v = Number(val);
        if (isCustom) {
            if (isNumber(v) && v >= 0 && v < 100) {
                setCustomTip(parseInt(val, 10));
            }
        } else {
            if (!customTip) {
                setCustomTip(tipDefault);
            }
        }
        setStandardTip();
        setIsCustom(true);
    };

    const handleChange = (event) => {
        updateCustomTip(event.target.value);
    };

    return (
        <>
            <Typography variant="h6">Tip</Typography>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: 5,
                }}
            >
                {tipStandard.map((val) => (
                    <TipStandard
                        key={val}
                        value={val}
                        standardTip={standardTip}
                        isCustom={isCustom}
                        onClickTip={updateStandardTip}
                    />
                ))}
                <TipCustom
                    isCustom={isCustom}
                    customTipPercent={customTip}
                    customTipValue={customTipValue}
                    onChange={handleChange}
                />
            </div>
        </>
    );
}

TipSection.propTypes = {};

export default TipSection;
