import React from 'react';
import styled from 'styled-components';
import { Title } from '../Styles/title';
import { corianderGreen } from '../Styles/colors';

const QuantityInputStyled = styled.input`
    font-size: 15px;
    width: 23px;
    text-align: center;
    border: none;
    outline: none;
`;

const QuantityTitle = {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#222C31',
};
// height: 24px;
const IncrementContainer = styled(Title)`
    padding-top: 10px;
    display: flex;
    height: 24px;
    margin: 10px 0px 0px 0px;
`;

const IncrementButton = styled.div`
  width: 23px;
  height: 23px
  color: ${corianderGreen};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin: 0px 10px 5px;
  ${({ disabled }) =>
      disabled &&
      `opacity: 0.5; 
     pointer-events: none; 
     `}
  &:hover {
    background-color: "#81ecec";
  }
`;

export function QuantityInput({ quantity }) {
    return (
        <IncrementContainer>
            <div className={QuantityTitle}>
                <b>Quantity</b>
            </div>
            <IncrementButton
                onClick={() => {
                    quantity.setValue(quantity.value - 1);
                }}
                disabled={quantity.value === 1}
            >
                <i
                    className="fas fa-minus-circle"
                    style={{ fontSize: '27px' }}
                ></i>
            </IncrementButton>
            <QuantityInputStyled {...quantity} />
            <IncrementButton
                onClick={() => {
                    quantity.setValue(quantity.value + 1);
                }}
            >
                <i
                    className="fas fa-plus-circle"
                    style={{ fontSize: '27px' }}
                ></i>
            </IncrementButton>
        </IncrementContainer>
    );
}
