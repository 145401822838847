import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { formatPrice } from '../../Data/FoodData';

const OrderTotalEntryContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
function OrderTotalEntry({ label, value, variant }) {
    const price = formatPrice(value / 100);
    return (
        <OrderTotalEntryContainer>
            <Typography variant={variant}>{label}:</Typography>
            <Typography variant={variant}>{price}</Typography>
        </OrderTotalEntryContainer>
    );
}

OrderTotalEntry.propTypes = {};

export default OrderTotalEntry;
