import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import { formatPrice } from '../../Data/FoodData';
import { getPrice, getToppingsStr } from '../../FoodDialog/FoodDialog';
import FoodItem from './FoodItem';

function CartItem({ id, index }) {
    const orderData = useContext(OrderContext);
    const { orders, dispatchOrder, setOpenFood } = orderData;

    const order = orders[id];

    const handleDelete = () => {
        dispatchOrder({ type: 'DELETE', key: id, payload: order });
    };

    function handleEdit() {
        setOpenFood({
            ...order,
            key: id,
            index,
        });
    }

    const {
        name: title,
        quantity,
        choice,
        modifiersStr: modifiers,
        notes,
    } = order;
    const price = formatPrice(getPrice(order) / 100);
    const toppings = getToppingsStr(order);

    return (
        <>
            <FoodItem
                quantity={quantity}
                title={title}
                choice={choice}
                toppings={toppings}
                modifiers={modifiers}
                notes={notes}
                price={price}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
        </>
    );
}

CartItem.propTypes = {};

export default CartItem;
