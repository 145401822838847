import { createTheme } from '@mui/material/styles';

const formTheme = createTheme({
    props: {
        MuiButton: {
            // size: 'small',
        },
    },
    overrides: {},
    palette: {
        primary: {
            main: '#36B37E',
            light: '#68B39F',
            dark: '#288F8E',
            contrastText: '#fff',
        },
        secondary: {
            main: '#7777F7',
            light: '#ADADF8',
            dark: '#506DF4',
            contrastText: '#fff',
        },
        tertiary: {
            main: '#222C31',
            // light: '#7986cb',
            // dark: '#303f9f',
            contrastText: '#fff',
        },
        neutral1: {
            main: '#F2F3F3',
            // light: '#7986cb',
            // dark: '#303f9f',
            // contrastText: '#fff',
        },
        neutral2: {
            // main: '#222C31',
            // light: '#7986cb',
            // dark: '#303f9f',
            // contrastText: '#fff',
        },
    },

    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: 'TT Norms Pro, Roboto, sans-serif;',
        fontWeight: 400,
        letterSpacing: 0,
        color: '#222C31',
        h1: {
            fontSize: '3.75rem',
            lineHeight: 1.6667,
            letterSpacing: -1.5,
        },
        h2: {
            fontSize: '2.813rem',
            lineHeight: 1.2,
        },
        h3: {
            fontSize: '2.188rem',
            lineHeight: 1.1714,
        },
        h4: {
            fontSize: '1.563rem',
            fontWeight: 500,
            lineHeight: 1.24,
        },
        h5: {
            marginBottom: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.35,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: 1.3125,
        },
        subtitle1: {
            fontSize: '1.125rem',
            fontWeight: 500,
            letterSpacing: 0.15,
            lineHeight: 1.7778,
            color: 'rgba(34, 44, 49, 0.6)',
        },
        subtitle2: {
            fontSize: '.875rem',
            fontWeight: 500,
            letterSpacing: 0.1,
            lineHeight: 1.5714,
            color: 'rgba(34, 44, 49, 0.6)',
        },
        body1: {
            fontSize: '1rem',
            letterSpacing: 0.15,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '.75rem',
            letterSpacing: 0.17,
            lineHeight: 1.4286,
        },
        body3: {
            fontFamily: 'TT Norms Pro, Roboto, sans-serif;',
            fontSize: '.675rem',
            letterSpacing: 0.18,
            lineHeight: 1.4286,
        },
        body4: {
            fontFamily: 'TT Norms Pro, Roboto, sans-serif;',
            fontSize: '.675rem',
            letterSpacing: 0.18,
            lineHeight: 1.4286,
            color: 'rgb(240, 240, 240)',
        },
        button: {
            fontSize: '1.1rem',
        },
        caption: {
            // font-size: 10px;
            // line-height: 17px;

            fontSize: '0.625rem',
            lineHeight: 1.7,
            color: 'gray',
        },
        overline: {
            fontSize: '0.65rem',
            color: 'gray',
        },
    },

    Link: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: 'TT Norms Pro, Roboto, sans-serif;',
        fontWeight: 400,
        letterSpacing: 0,
        color: '#222C31',
        body1: {
            fontSize: '1rem',
            letterSpacing: 0.15,
            lineHeight: 1.5,
        },
    },

    components: {
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    // Adjust spacing to reach minimal touch target hitbox
                    marginLeft: 4,
                    marginRight: 4,
                    padding: 12,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: 8,
                    marginBottom: 8,
                    height: 1,
                    // backgroundColor: '#52be7f',
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                labelContainer: {
                    overflow: 'visible',
                },
            },
        },
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        body1: {
            fontSize: '.875rem',
            letterSpacing: 0.17,
            lineHeight: 1,
        },
        body2: {
            fontSize: '.875rem',
            letterSpacing: 0.17,
            lineHeight: 1,
        },
    },
});

export default formTheme;
