import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import { formatPrice } from '../../Data/FoodData';
import { getPrice, getToppingsStr } from '../../FoodDialog/FoodDialog';
import FoodItem from './FoodItem';

function getRatingsKey(item) {
    var key = item.id
        ? item.id
        : // firebase keys cannot contain ., $, #, [, ], /,
          item.name.replace(/[.$#[]\/]/g, ' ');
    return key;
}

function ReviewItem({ id, index, showStarRating }) {
    const orderData = useContext(OrderContext);
    const { orders, getRatings, dispatchReviews, reviewSubmitted } = orderData;

    const order = orders[id];

    const {
        name: title,
        quantity,
        choice,
        modifiersStr: modifiers,
        notes,
    } = order;
    const price = formatPrice(getPrice(order) / 100);
    const toppings = getToppingsStr(order);

    const ratingsKey = getRatingsKey(order);
    const ratings = getRatings(ratingsKey) ? getRatings(ratingsKey).rating : 0;

    const setRating = (val) => {
        const value = {
            id: ratingsKey,
            rating: val,
            name: title || ratingsKey,
        };
        dispatchReviews({ key: ratingsKey, value });
    };
    const handleChange = (e, val) => setRating(val);
    return (
        <div>
            <FoodItem
                quantity={quantity}
                title={title}
                choice={choice}
                toppings={toppings}
                showStarRating={showStarRating}
                ratings={ratings}
                modifiers={modifiers}
                notes={notes}
                readOnly={reviewSubmitted}
                price={price}
                onRatingChange={handleChange}
            />
        </div>
    );
}

ReviewItem.propTypes = {};

export default ReviewItem;
