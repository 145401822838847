import React from 'react';
import styled from 'styled-components';
//import { foods } from "../Data/FoodData";
import { formatPrice } from '../Data/FoodData';
import Collapsible from './Collapsible';

//import Collapsible from 'react-collapsible';

//  margin: 0px 400px 50px 20px;
//  height: 1000px;

const DialogContent = styled.div`
    overflow: auto;
    min-height: 20px;
    padding: 0px 40px;
    padding-bottom: 20px;
`;
const RestaurantNameDiv = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #000000;
`;
export const MenuStyled = styled.div`
    margin: 50px 100px 100px 100px;
    // Add this part
    @media (max-width: 1000px) {
        margin: 50px 50px 100px 50px;
    }
    @media (max-width: 720px) {
        width: 100%;
        margin: auto;
        margin-bottom: 100px;
        margin-top: 50px;
    }
`;

export const MenuContent = styled(DialogContent)`
    padding: 20px;
    height: 100%;
`;

export const FoodGrid = styled.div`
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 8px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;

// ${props => props.img !== undefined && `
//   position: absolute;
//   justify-content: space-between;
// `}

//  background-color: rgba(255, 255, 255, 0.8);
// padding: 5px;
export const FoodLabel = styled.div`
    ${(props) =>
        props.img !== undefined
            ? `position: absolute;`
            : `justify-content: space-between;   display: flex;`}
`;

export const FoodContainer = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${(props) => (props.oos ? '#c0392b11' : 'white')}

    &:hover {
      cursor: pointer;
      background-color: ${(props) => (props.oos ? '#c0392b11' : '#f8f8f8')};
    }
`;

export const Description = styled.div`
    color: gray;
    font-size: 13px;
    margin-top: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Tagline = styled.div`
    color: #333333 100%;
    opacity: 0.5;
    font-size: 14px;
    word-spacing: 0px;
    text-align: center;
    margin-top: 10px;
    fontweight: bold;
`;

export const Notes = styled.div`
    color: #333333 100%;
    opacity: 0.5;
    font-size: 12px;
    word-spacing: 0px;
    text-align: center;
    fontweight: bold;
`;

export const Hours = styled.div`
    color: #27ae60;
    font-size: 15px;
    word-spacing: 0px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    fontweight: bold;
`;

export const daysOfTheWeek = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

export const PauseMessage = styled.div`
    color: #e74c3c;
    font-size: 15px;
    word-spacing: 0px;
    text-align: center;
    margin-top: 10px;
    fontweight: bold;
`;

//Collapsible trigger={sectionName}

export const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
`;

export const CurrentlyClosed = styled.div`
    background-color: #e74c3c;
    color: #ffffff;
    border-radius: 5px;
`;

export function Menu2({
    setOpenFood,
    menu,
    restauHours,
    areOrdersPaused,
    outsideBizHours,
    restauName,
}) {
    //TODO: need to get day from Timezone of the restaurant. Currently it is from the client perspective.
    var dateIndex = new Date().getDay();
    var day = daysOfTheWeek[dateIndex];
    var nextDay = daysOfTheWeek[(dateIndex + 1) % 7];
    // Test Cases
    //day = "Monday"; nextDay= "Tuesday";

    function hoursString(hoursArray) {
        var hours = '';
        hoursArray.forEach(
            (slot) => (hours += slot.start + ' - ' + slot.end + '; ')
        );
        return hours.slice(0, -2);
    }

    return (
        <MenuStyled>
            <Tagline>Same prices. More convenience.</Tagline>
            {/* <RestaurantNameDiv>{restauName}</RestaurantNameDiv> */}
            {day in restauHours ? (
                <Hours>
                    {' '}
                    <i className="fas fa-clock"></i>
                    {restauHours[day].length > 0
                        ? ' Open today: ' + hoursString(restauHours[day])
                        : ' Closed ' + day}{' '}
                </Hours>
            ) : (
                // Assumes restaurants are not closed on consecutive days
                <>
                    {nextDay in restauHours &&
                    restauHours[nextDay].length > 0 ? (
                        <Hours>
                            {' '}
                            <i className="fas fa-clock"></i> Open tomorrow:{' '}
                            {hoursString(restauHours[nextDay])}
                        </Hours>
                    ) : (
                        <></>
                    )}
                </>
            )}
            {areOrdersPaused ? (
                <PauseMessage>
                    {' '}
                    We are not taking new orders at this time. Please try again
                    later.{' '}
                </PauseMessage>
            ) : (
                <></>
            )}
            {outsideBizHours && !areOrdersPaused ? (
                <>
                    <CenterDiv>
                        <CurrentlyClosed>
                            <b>&nbsp;Currently closed&nbsp;</b>
                        </CurrentlyClosed>
                    </CenterDiv>
                    <Notes>
                        {' '}
                        Orders placed now can only be processed when the
                        restaurant opens again{' '}
                    </Notes>
                </>
            ) : (
                <></>
            )}

            <MenuContent>
                {menu &&
                    menu.map((section, sidx) =>
                        section ? (
                            <Collapsible
                                key={section.id}
                                trigger={section.name}
                                open={sidx === 0}
                            >
                                <FoodGrid>
                                    {section.foods.map((food, fidx) =>
                                        food ? (
                                            <FoodContainer
                                                key={section.id + food.id}
                                                oos={food.oos}
                                                onClick={() => {
                                                    food.oos
                                                        ? alert(
                                                              food.name +
                                                                  ' is temporarily out of stock'
                                                          )
                                                        : setOpenFood(food);
                                                }}
                                            >
                                                <FoodLabel>
                                                    <div
                                                        style={{
                                                            marginRight: '20px',
                                                        }}
                                                    >
                                                        <span opacity="0.5">
                                                            <b>{food.name}</b>{' '}
                                                        </span>
                                                        {food.oos ? (
                                                            <span
                                                                style={{
                                                                    backgroundColor:
                                                                        '#e74c3c',
                                                                    color: '#FFFFFF',
                                                                    borderRadius:
                                                                        '5px',
                                                                }}
                                                            >
                                                                <b>
                                                                    &nbsp;Sold
                                                                    Out&nbsp;
                                                                </b>
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div>
                                                        {food.pricelabel
                                                            ? food.pricelabel
                                                            : formatPrice(
                                                                  food.price /
                                                                      100
                                                              )}
                                                    </div>
                                                    {/* <div>{food.pricelabel? food.pricelabel: formatPrice(food.price)}</div> */}
                                                </FoodLabel>
                                                <Description>
                                                    {food.desc}
                                                </Description>
                                            </FoodContainer>
                                        ) : (
                                            <div key={sidx + ' ' + fidx} />
                                        )
                                    )}
                                </FoodGrid>
                            </Collapsible>
                        ) : (
                            <div key={sidx} />
                        )
                    )}
            </MenuContent>
        </MenuStyled>
    );
}
