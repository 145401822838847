/**
 * This is for phone orders only
 * User can not edit cart OR delete items from cart OR add items to cart
 * User selects the tip and then click Pay Here
 */

import { Divider } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { OrderContext } from '../context/orderContext';
import CartAction from './components/CartAction';
import OrderTitle from './components/OrderTitle';
import OrderTotal from './components/OrderTotalSection';
import ReviewItemList from './components/ReviewItemList';
import TipSection from './components/TipSection';
import OrderInstructions from './containers/OrderInstructions';
import { getOrderReadyByString, getTip } from './Order.utils';
import makePayment from './utils/makePayment';

function PayHere(props) {
    const context = useContext(OrderContext);
    const {
        qsrMode,
        tableId,
        orderUnderName,
        orderNum,
        getOrderSubtotal,
        tipPercent,
        scheduledDate,
        scheduledTime,
    } = context;

    const [pending, setPending] = useState(false);

    const subtotal = getOrderSubtotal();
    const tip = getTip(subtotal, tipPercent);

    return (
        <>
            <OrderTitle
                qsrMode={qsrMode}
                tableId={tableId}
                name={orderUnderName}
            >
                your order
                {orderNum ? ` #${orderNum} ` : ' '}
                will be ready{' '}
                {getOrderReadyByString(scheduledDate, scheduledTime)}. Make
                contactless payment and skip the wait.
            </OrderTitle>

            <ReviewItemList />

            <Divider />

            <TipSection />

            <Divider />

            <OrderTotal compute={true} />

            <OrderInstructions disabled />

            <CartAction
                label="Pay Here"
                disabled={pending}
                pending={pending}
                onCartSubmit={() => {
                    makePayment(context, tip, setPending);
                }}
            />
        </>
    );
}

PayHere.propTypes = {};

export default PayHere;
