import React, { useContext } from 'react';
import FormContext from '../FormContext';
import { OrderContext } from '../../context/orderContext';
import { NavbarStyled, Logo } from '../../Navbar/Navbar';
import Config from '../../config/Config';
import { Banner } from '../../Banner/Banner';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 100%;
    margin: auto;

    @media (min-width: 720px) {
        max-width: 70%;
    }
`;

const FormContent = ({ children }) => {
    const { snackbarOpen, setSnackbarOpen, snackbarMessage } =
        useContext(FormContext);
    const orders = useContext(OrderContext);

    return (
        <>
            <NavbarStyled>
                <Logo>
                    {Config.environment}
                    {orders.restauName}
                </Logo>
            </NavbarStyled>
            <Banner
                img={orders.bannerUrl}
                imgDesktop={orders.bannerUrlDesktop}
                bannerImageParts={orders.bannerImageParts}
            />

            <Container>{children}</Container>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                message={snackbarMessage}
                onClose={(event, reason) => {
                    if (reason === 'clickaway') return;
                    setSnackbarOpen(false);
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(event, reason) => {
                            if (reason === 'clickaway') return;
                            setSnackbarOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};

export default FormContent;
