/**
 * Custom theme object is defined here which will be used for
 * controlling theming of MUI components
 *
 * Default theme details are available here
 * https://v4.mui.com/customization/default-theme/
 *
 * The customization details can be found here
 * https://v4.mui.com/customization/theming/
 */
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    props: {
        MuiButton: {
            size: 'small',
        },
    },
    overrides: {
        MuiIconButton: {
            sizeSmall: {
                // Adjust spacing to reach minimal touch target hitbox
                marginLeft: 4,
                marginRight: 4,
                padding: 12,
            },
        },
        MuiDivider: {
            root: {
                marginTop: 8,
                marginBottom: 8,
                height: 1,
                // backgroundColor: '#52be7f',
            },
        },
    },
    palette: {
        primary: {
            main: '#27ae60',
            light: '#52be7f',
            dark: '#1b7943',
            contrastText: '#fff',
        },
        secondary: {
            // main: purple[500],
            light: '#7986cb',
            main: '#3f51b5',
            dark: '#303f9f',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif"',
        fontSize: 12,
        caption: {
            fontWeight: 400,
            fontSize: '0.65rem',
            height: '0.65rem',
            lineHeight: 0,
            letterSpacing: 0,
            color: 'gray',
        },
    },
});

export default theme;
