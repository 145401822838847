import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';

const CardStyled = styled(Card)`
    text-align: center;
    margin: 15px 0;
    padding: 5px;
`;
function TimeToCard({ message, duration }) {
    return (
        <CardStyled raised>
            <Typography color="error">{message}</Typography>
            <Typography variant="h6" color="error">
                {duration}
            </Typography>
        </CardStyled>
    );
}

TimeToCard.propTypes = {};

export default TimeToCard;
