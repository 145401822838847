export default function (url) {
    return {
        create_checkout_session_url: url + 'create_checkout_session',
        get_order_details_url: url + 'get_order_details',
        create_paylater_order_url: url + 'create_paylater_order',
        make_payment_url: url + 'make_pickup_payment',
        get_chain_details_url: url + 'get_chain_details',
        report_error_url: url + 'report_error',
        report_error_url: url + 'report_error',
        create_checkout_thirdparty: url + 'create_checkout_thirdparty',
        get_catering_form_config: url + 'get_catering_form_config',
        create_catering_inquiry: url + 'create_catering_inquiry',
        update_catering_inquiry: url + 'update_catering_inquiry',
        create_catering_inquiry_async: url + 'create_catering_inquiry_async',
    };
}
