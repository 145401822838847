import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import PhoneNumber from '../components/PhoneNumber';
import handleKeyPress from '../utils/handleKeyPress';

function OrderTelephone(props) {
    const { qsrMode, phoneNumber, setPhoneNumber } = useContext(OrderContext);

    const message = qsrMode
        ? 'Based on restaurant operations/settings, you will either receive a text when food is ready for pickup at the counter or food will be served at your table.'
        : 'You will receive a text with estimated time and when your order is ready.';

    const handleChange = (event) => {
        const value = event.target.value || '';
        const number = value.replace(/\D/g, '');
        setPhoneNumber(number);
    };

    return (
        <PhoneNumber
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            value={phoneNumber}
            message={message}
            {...props}
        />
    );
}

OrderTelephone.propTypes = {};

export default OrderTelephone;
