import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

const OrderTitleContainer = styled.div`
    margin-bottom: 10px;
`;
function OrderTitle({ children, name, tableId, qsrMode }) {
    return (
        <OrderTitleContainer>
            {qsrMode && tableId && (
                <div>
                    <Typography variant="h6">Table-{tableId}</Typography>
                </div>
            )}
            <div>
                <Typography variant="h6">
                    {name ? `Hey ${name},` : 'Hey,'}
                </Typography>
            </div>
            <div>
                <Typography variant="h6">{children}</Typography>
            </div>
        </OrderTitleContainer>
    );
}

OrderTitle.propTypes = {};

export default OrderTitle;
