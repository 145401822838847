import getDateTime from '../../Utils/getDateTime';

function saveReview(context) {
    const {
        reviews,
        reviewComment, //
        appComment, //
        orderHistoryId,
        restauId,
        enqueueSnackbar,
        setReviewSubmitted, //
    } = context;

    //debugger;
    const database = window.customerApp.database();
    var reviewDb = {};
    var ratings = {};
    for (var r in reviews) {
        if (reviews[r].rating) {
            ratings[r] = reviews[r];
        }
    }

    if (
        Object.keys(ratings).length > 0 ||
        reviewComment.value.trim() !== '' ||
        appComment.value.trim() !== ''
    ) {
        if (reviewComment.value.trim() !== '') {
            reviewDb['comments'] = reviewComment.value;
        }

        if (appComment.value.trim() !== '') {
            reviewDb['app_feedback'] = appComment.value;
        }

        var dateTime = getDateTime();
        reviewDb['time'] = dateTime;
        reviewDb['orderHistoryId'] = orderHistoryId;
        reviewDb['ratings'] = ratings;
        var reviewRef = database.ref('reviews/' + restauId).push();
        console.log('review to save ', reviewDb);
        reviewRef.set(reviewDb);
        enqueueSnackbar('Thank You for feedback. Review is saved', {
            variant: 'success',
        });
        setReviewSubmitted(reviewRef.key);
    }
}

export default saveReview;

// TODO: Confirm if we need this functionality in future.
// function updateReview(){
//   const database = window.customerApp.database();
//   var review = {...reviews};
//   if (reviewComment.value !== '') {
//      review['comments'] = reviewComment.value;
//   }

//   if (Object.keys(review).length > 0) {
//     var dateTime = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
//     review['time'] = dateTime;
//     review['orderHistoryId'] = orderHistoryId;
//     var updates = {};
//     updates["reviews/"+restauId+"/"+reviewSubmitted] = review;
//     enqueueSnackbar("Review is updated", {variant:'success'});
//     database.ref().update(updates);
//   }
// }
