import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const FlexDiv = styled.div`
    line-height: 11px;
    padding-top: 2px;
    padding-bottom: 2px;
`;
FlexDiv.displayName = 'FlexDiv';

function Caption({ children }) {
    return (
        <FlexDiv>
            <Typography variant="caption">{children}</Typography>
        </FlexDiv>
    );
}

Caption.propTypes = {};

export default Caption;
