import TextField from '@material-ui/core/TextField';
import React from 'react';

function Instructions({ onChange, value, ...others }) {
    return (
        <div>
            <TextField
                placeholder="spice level, ..."
                label="Order Instructions"
                name="instructions"
                fullWidth
                multiline
                minRows={2}
                margin="normal"
                variant="outlined"
                value={value}
                size="small"
                {...others}
                onChange={onChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    );
}

Instructions.propTypes = {};

export default Instructions;
