import React from 'react';
import FoodSection from './FoodSection';
import { MenuContent } from './FoodItem.styles';

export default function MenuTemplate({
    template,
    setOpenFood,
    menu,
    showCount,
}) {
    return (
        <MenuContent>
            {menu &&
                menu.map((section, sidx) =>
                    section && section.foods ? (
                        <FoodSection
                            key={section.id}
                            sidx={sidx}
                            template={template}
                            setOpenFood={setOpenFood}
                            showCount={showCount}
                            {...section}
                        />
                    ) : (
                        <div key={sidx} />
                    )
                )}
        </MenuContent>
    );
}
