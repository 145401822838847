import React from 'react';
import useForm from '../useForm';
import FormContext from '../FormContext';
import FormContent from '../Components/FormContent';
import { GlobalStyle } from '../../Styles/GlobalStyle';
import { Outlet } from 'react-router';
import { ThemeProvider } from '@mui/material/styles';
import formTheme from '../formTheme';

const FormRoot = () => {
    const formHook = useForm();
    return (
        <ThemeProvider theme={formTheme}>
            <FormContext.Provider value={formHook}>
                <GlobalStyle />
                <FormContent>
                    <Outlet />
                </FormContent>
            </FormContext.Provider>
        </ThemeProvider>
    );
};

export default FormRoot;
