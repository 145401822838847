import React from 'react';
import PropTypes from 'prop-types';
import FoodTitle from './FoodTitle';
import FoodPrice from './FoodPrice';

import {
    FoodContainer,
    FoodLabel,
    ImageDetails,
    Title,
    Description,
} from './FoodItem.styles';

function FoodItemImageLeft({ food, setOpenFood }) {
    const foodImage = food.img || '/menu-images/stock_img.jpg';

    return (
        <FoodContainer
            oos={food.oos}
            onClick={() => {
                food.oos
                    ? alert(food.name + ' is temporarily out of stock')
                    : setOpenFood(food);
            }}
        >
            <FoodLabel>
                <img
                    src={foodImage}
                    alt={food.name}
                    style={{
                        width: '94px',
                        height: '94px',
                        objectFit: 'cover',
                        borderRadius: '5%',
                    }}
                />

                <ImageDetails>
                    <Title>
                        <FoodTitle name={food.name} oos={food.oos} />
                    </Title>
                    <Description>{food.desc}</Description>
                    <div
                        style={{
                            paddingLeft: '10px',
                            paddingTop: '5px',
                            fontSize: '15px',
                        }}
                    >
                        <FoodPrice {...food} />
                    </div>
                </ImageDetails>
            </FoodLabel>
        </FoodContainer>
    );
}

FoodItemImageLeft.propTypes = {};

export default FoodItemImageLeft;
