import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Welcome from './Welcome';
import StoreRoot from './StoreRoot';
import ChainRoot from './ChainRoot';
import { OrderContext } from './context/orderContext';
import { useSnackbar } from 'notistack';
import { useOpenFood } from './Hooks/useOpenFood';
import { useShowOrders } from './Hooks/useShowOrders';
import { useOrders } from './Hooks/useOrders';
import FormRoot from './Catering/Containers/FormRoot';
import FormPage from './Catering/Components/FormPage';
import ViewOrderPage from './Catering/Components/ViewOrderPage';
import SubmissionPage from './Catering/Components/SubmissionPage';

export default function AppRoutes() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const openFood = useOpenFood();
    const showOrders = useShowOrders();
    const ordersHook = useOrders(
        enqueueSnackbar,
        closeSnackbar,
        showOrders,
        openFood
    );
    return (
        <OrderContext.Provider value={ordersHook}>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Welcome />}></Route>
                    {['/chain/:chainId', ':temp/chain/:chainId'].map((path) => (
                        <Route path={path} element={<ChainRoot />}></Route>
                    ))}
                    <Route path="/catering" element={<FormRoot />}>
                        {[
                            '/catering/:restauId',
                            '/catering/:restauId/:inquiryId',
                        ].map((path) => (
                            <Route path={path} element={<FormPage />} />
                        ))}
                        {[
                            '/catering/:restauId/:inquiryId/success',
                            '/catering/:restauId/:inquiryId/kitchen',
                        ].map((path) => (
                            <Route path={path} element={<ViewOrderPage />} />
                        ))}
                        <Route
                            path="/catering/:restauId/success"
                            element={<SubmissionPage />}
                        />
                    </Route>
                    {[
                        '/:restauId',
                        '/store/:restauId',
                        '/:temp/store/:restauId',
                        '/:temp/:restauId',
                    ].map((path) => (
                        <Route path={path} element={<StoreRoot />}></Route>
                    ))}
                </Routes>
            </BrowserRouter>
        </OrderContext.Provider>
    );
}
