import styled from 'styled-components';
import { corianderGreen } from '../Styles/colors';
import { Title } from '../Styles/title';

const OrderButton = styled(Title)`
    margin-bottom: 8px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background-color: ${corianderGreen};
`;
OrderButton.displayName = 'OrderButton';

const LocationLabel = styled.div`
    display: grid;
    grid-template-columns: auto 100px;
    grid-gap: 10px;
`;
LocationLabel.displayName = 'LocationLabel';

const ChainStyled = styled.div`
    margin: 100px 100px 100px 100px;
    // Add this part
    @media (max-width: 1000px) {
        margin: 100px 50px 100px 50px;
    }
    @media (max-width: 720px) {
        width: 100%;
        margin: auto;
        margin-bottom: 100px;
        margin-top: 100px;
    }
`;
ChainStyled.displayName = 'ChainStyled';

const LocationGrid = styled.div`
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        gap: 50px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 8px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    padding-bottom: 20px;
`;
LocationGrid.displayName = 'LocationGrid';

const LogoImg = styled.img`
    @media (max-width: 720px) {
        height: 175px;
        width: 175px;
    }
    height: 300px;
    width: 300px;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 80px;
`;
LogoImg.displayName = 'LogoImg';

const LocationContainer = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${(props) => (props.oos ? '#c0392b11' : 'white')}

    &:hover {
      background-color: ${(props) => (props.oos ? '#c0392b11' : '#f8f8f8')};
    }
`;
LocationContainer.displayName = 'LocationContainer';

export {
    OrderButton,
    LocationLabel,
    ChainStyled,
    LocationGrid,
    LogoImg,
    LocationContainer,
};
