import React from 'react';
import { Card, Typography } from '@mui/material';
import Caption from '../../Order/components/Caption';
import { formatPrice } from '../../Data/FoodData';

const OrderItem = ({ item, kitchenToggle }) => {
    return (
        <Card style={{ padding: 4, marginBottom: 4, width: '100%' }}>
            <div style={{ display: 'flex', minHeight: 40 }}>
                <div style={{ width: 40, flexShrink: 0 }}>
                    <Typography variant="body2">
                        {item.quantity}
                        <Typography variant="caption">&nbsp;x</Typography>
                    </Typography>
                </div>
                <div style={{ flexGrow: 1, padding: '0 10px' }}>
                    <Typography color="primary.main" variant="body2">
                        {item.name}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {item.choice && <Caption>{item.choice}</Caption>}
                        {item.toppings && <Caption>{item.toppings}</Caption>}
                        {item.modifiers && <Caption>{item.modifiers}</Caption>}
                        {item.notes && (
                            <Caption>
                                <i className="fas fa-info-circle"></i>{' '}
                                {item.notes}
                            </Caption>
                        )}
                    </div>
                </div>
                {!kitchenToggle && (
                    <div>
                        <Typography variant="body2">
                            {formatPrice((item.quantity * item.price) / 100)}
                        </Typography>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default OrderItem;
