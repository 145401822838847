import React, { useContext } from 'react';
import FormContext from '../FormContext';
import {
    Grid,
    Typography,
    Box,
    Card,
    CardContent,
    CardMedia,
} from '@mui/material';

const FormHeader = () => {
    const { formConfig } = useContext(FormContext);
    return (
        <>
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                >
                    {formConfig.form_title || 'Catering Form'}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        flexWrap: 'nowrap',
                        justifyContent: {
                            xs:
                                formConfig.custom_message &&
                                formConfig.custom_message.length < 2
                                    ? 'center'
                                    : 'flex-start',
                            md: 'center',
                        },
                        width: '100%',
                    }}
                >
                    {formConfig.custom_message &&
                        formConfig.custom_message.map(
                            (msg, i) =>
                                msg.text && (
                                    <Box
                                        key={i}
                                        sx={{
                                            minWidth: 300,
                                            maxWidth: 300,
                                            minHeight: 160,
                                            maxHeight: 160,
                                            margin: 1,
                                        }}
                                    >
                                        <Card
                                            align="center"
                                            sx={{
                                                display: 'flex',
                                                height: '100%',
                                                width: '100%',
                                                backgroundColor:
                                                    'tertiary.main',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                }}
                                            >
                                                <CardContent
                                                    sx={{
                                                        overflow: 'auto',
                                                        padding: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body4"
                                                        align="center"
                                                    >
                                                        {msg.text
                                                            .split('\n')
                                                            .map(
                                                                (
                                                                    line,
                                                                    index
                                                                ) => (
                                                                    <React.Fragment
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            {msg.image &&
                                            msg.image.length !== 0 ? (
                                                <CardMedia
                                                    component="img"
                                                    image={msg.image}
                                                    alt="promotion msg"
                                                    sx={{ width: '50%' }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </Card>
                                    </Box>
                                )
                        )}
                </Box>
            </Grid>
        </>
    );
};

export default FormHeader;
