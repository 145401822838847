import React, { useContext } from 'react';
import FormContext from '../FormContext';
import OrderItem from './OrderItem';
import OrderTotalEntry from '../../Order/components/OrderTotalEntry';
import LeadDetails from './LeadDetails';
import {
    Grid,
    Typography,
    Divider,
    TextField,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';

const OrderSummary = () => {
    const { strippedInquiryData, kitchenToggle } = useContext(FormContext);
    return (
        <Grid container>
            <Grid item xs={12}>
                <LeadDetails details={strippedInquiryData.leadDetails} />
            </Grid>

            <Grid item xs={12}>
                <Typography sx={{ font: '0.7rem', fontWeight: 'bold' }}>
                    Summary of your ordered items:
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {Object.values(strippedInquiryData.orders.items).map((item) => (
                    <OrderItem
                        key={item.key}
                        item={item}
                        kitchenToggle={kitchenToggle}
                    />
                ))}
            </Grid>

            {!kitchenToggle && (
                <Grid item xs={12}>
                    <OrderTotalEntry
                        label="SubTotal before Discount"
                        value={
                            strippedInquiryData.orders
                                .payment_subtotal_before_discount
                        }
                        variant="body2"
                    />
                    {strippedInquiryData.orders.discount.amount !== 0 && (
                        <OrderTotalEntry
                            label="Discount"
                            value={strippedInquiryData.orders.discount.amount}
                            variant="body2"
                        />
                    )}
                    <OrderTotalEntry
                        label="Tax"
                        value={strippedInquiryData.orders.payment_salestax}
                        variant="body2"
                    />
                    <Divider />
                    <OrderTotalEntry
                        label="Total"
                        value={strippedInquiryData.orders.payment_total}
                    />
                </Grid>
            )}

            {strippedInquiryData.orders.orderInstruction && (
                <Grid item xs={12}>
                    <Table size="small" sx={{ mb: 2 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    variant="head"
                                    align="left"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Order Instructions:
                                </TableCell>
                                <TableCell align="left">
                                    {
                                        strippedInquiryData.orders
                                            .orderInstruction
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            )}

            {kitchenToggle &&
                strippedInquiryData.orders.orderInstructionInternal && (
                    <Grid item xs={12}>
                        <Table size="small" sx={{ mb: 2 }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Internal Order Instructions:
                                    </TableCell>
                                    <TableCell align="left">
                                        {
                                            strippedInquiryData.orders
                                                .orderInstructionInternal
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                )}
        </Grid>
    );
};

export default OrderSummary;
