/**
 * @param {string} targetUrl
 *  url that should be updated for query-params and returned.
 *  Eg: https://strideq.com/hyderabad-dum-biryani-milpitas-ca/store/hdbmilpitas?param1=newParam
 *
 * @param {string} search
 *  query-params stored in url search string.
 *  Eg: ?test=123&company=strideq&app=takeout
 *
 * @returns {string} url
 *
 * @description
 *  adds the query-params found in the "search" string to "targetUrl"
 *  only single-value params are supported,
 *      if a multi-value param is present on targetUrl then
 *          if param with same key present in search string
 *              --> original multi-value param will be replaced with single-value from search string
 *          if param with same key not present in search string
 *              --> original multi-value param will be persisted on targetUrl
 *  query-param values from search string will overwrite the params present in targetUrl
 */
export function setQueryParams(targetUrl = '', search = '') {
    const params = new URLSearchParams(search);
    const target = new window.URL(targetUrl);
    params.forEach((value, key) => target.searchParams.set(key, value));
    return target.toString();
}

/**
 * @param {string} url
 * @description
 *  adds query-params from current window to the input url and sets that as new window location
 */
export function loadStorePage(url = '') {
    const updated = setQueryParams(url, window.location.search);
    window.location.assign(updated);
}
