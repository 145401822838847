import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice, isDiscountedPrice } from '../../Data/FoodData';
import { StrikePrice } from './FoodItem.styles';

function FoodPrice({ pricelabel, original_price, price }) {
    return (
        <>
            {pricelabel ? pricelabel : formatPrice(price / 100)}{' '}
            {isDiscountedPrice(original_price, price) && (
                <StrikePrice>{formatPrice(original_price / 100)}</StrikePrice>
            )}
        </>
    );
}

FoodPrice.propTypes = {};

export default FoodPrice;
