/**
 * This is for both online and phone orders
 * Displayed after successful payment
 * User can rate
 *  - overall experience,
 *  - rate restaurant,
 *  - individual food items
 */

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { OrderContext } from '../context/orderContext';
import CartAction from './components/CartAction';
import OrderTitle from './components/OrderTitle';
import OrderTotal from './components/OrderTotalSection';
import Promotion from './components/Promotion';
import RatingSection from './components/RatingSection';
import ReviewItemList from './components/ReviewItemList';
import { getOrderReadyByString } from './Order.utils';
import saveReview from './utils/saveReview';
import startNewOrder from './utils/startNewOrder';

function Reviews({ toggleShowOrders }) {
    const context = useContext(OrderContext);

    const {
        qsrMode,
        tableId,
        promotionMessages,

        pendingPayment,
        orderUnderName,
        orderNum,
        reviewSubmitted,
        scheduledTime,
        scheduledDate,
        receiptLink,
    } = context;
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        startNewOrder(context, toggleShowOrders);
                    }}
                >
                    Start New Order
                </Button>
            </div>

            {promotionMessages && promotionMessages.checkout_message && (
                <Promotion
                    message={promotionMessages.checkout_message}
                    link={promotionMessages.checkout_logo_link}
                    logo={promotionMessages.checkout_logo}
                />
            )}

            <OrderTitle
                qsrMode={qsrMode}
                tableId={tableId}
                name={orderUnderName}
            >
                your order
                {orderNum ? ` #${orderNum} ` : ' '}
                will be ready{' '}
                {getOrderReadyByString(scheduledDate, scheduledTime)}.
            </OrderTitle>

            <RatingSection />

            <ReviewItemList showStarRating />

            <Divider />

            <OrderTotal compute={false} />

            <Divider />

            {receiptLink && (
                <div style={{ textAlign: 'center' }}>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            window.open(
                                receiptLink,
                                '_blank' // <- This is what makes it open in a new window.
                            );
                        }}
                    >
                        View Receipt
                    </Button>
                    <Divider />
                </div>
            )}

            {reviewSubmitted === false ? (
                <CartAction
                    label="Submit Review"
                    onCartSubmit={() => {
                        saveReview(context);
                    }}
                />
            ) : (
                <div style={{ marginTop: 8 }}>
                    <Typography>Thank you for your feedback.</Typography>
                </div>
            )}

            <div style={{ marginTop: 8 }}>
                <Typography>Thanks for supporting local businesses!</Typography>
            </div>
        </>
    );
}

Reviews.propTypes = {};

export default Reviews;
