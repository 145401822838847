import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import reportErrors from './Utils/reportErrors';

const logErrorToMyService = async (errorDetails, type = 'error-boundary') => {
    console.log(type, errorDetails);
    return reportErrors({
        error: JSON.stringify(errorDetails, null, 2),
        type,
    });
};

const logUnhandledErrors = async (...args) => {
    // suppress errors from withEventHandlerCrash
    if (args[0].error && args[0].error.message === 'withEventHandlerCrash') {
        console.log(args[0].error.message);
        return;
    }
    logErrorToMyService(args, 'unhandled-errors');
};

const logHandledPromises = async (...args) => {
    logErrorToMyService(args, 'handled-promises');
};

const logUnHandledPromises = async (...args) => {
    logErrorToMyService(args, 'unhandled-promises');
};

const errorMessage = 'Error occurred. Refresh and try again';

class ErrorBoundary extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        logErrorToMyService([error, errorInfo]);
    }

    componentDidMount() {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', logUnHandledPromises);
        window.addEventListener('rejectionhandled', logHandledPromises);
        window.addEventListener('error', logUnhandledErrors);
        this.showNotification();
    }

    componentDidUpdate() {
        this.showNotification();
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', logUnHandledPromises);
        window.removeEventListener('rejectionhandled', logHandledPromises);
        window.removeEventListener('error', logUnhandledErrors);
    }

    showNotification() {
        if (this.state.hasError) {
            this.props.enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
        }
    }

    render() {
        // May add following options in future
        // - fallbackComponent
        // - fallbackNode
        // - fallbackAction
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // return <p style={{ color: 'red' }}>Something went wrong.</p>;
            return null;
        }

        return this.props.children;
    }
}

export default withSnackbar(ErrorBoundary);
